import { FC } from "react";
import openInNewTab from "../../../../../../../scripts/openInNewTab";

const LinkScreen: FC<{
    title: string,
    buttonText: string,
    img: string,
    link?: string,
    onClick?: () => void
}> = ({
    title,
    buttonText,
    img,
    link,
    onClick
}) => {
        return <div className='content-screen'>
            <div className='link-screen'>
                <img src={img} alt="" />
                <h3>{title}</h3>
                <button onClick={() => {
                    if (link)
                        openInNewTab(link);
                    if (onClick)
                        onClick();
                }} style={{
                    width: "fit-content"
                }} className='basic-button basic-button--gradient'>{buttonText}</button>
            </div>
        </div>;
    }

export default LinkScreen;