import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import TopPanel from './components/TopPanel/TopPanel';
import AppSelection from './components/AppSelection/AppSelection';
import "./ControlPanelPage.scss";
import AppContent from './components/AppContent/AppContent';
import { getAccessToken, getMyTmSellerBots } from '../../../../api/api';
import store from '../../../../store/store';
import { getUrlParams } from '../../../../scripts/parseUrl';
import { TMSellerTab, TrademanagerTab } from '../../../../enums/storeEnums';
import TMSellerContent from './components/AppContent/TMSellerContent/TMSellerContent';

const ControlPanelPage: FC<{}> = observer((props) => {
    useEffect(() => {
        getMyTmSellerBots((errorText, tmsellerBots) => {
            if (errorText) {
                if (errorText == 'не авторизован') {
                    getAccessToken(() => { });
                }
            } else {
                store.userAccountStore.user?.setTmsellerBots(tmsellerBots!);
            }
        });
        const params = getUrlParams(window.location.href);
        if (params) {
            switch (params.app) {
                case "trademanger":
                    store.userAccountStore.setSelectedApp("trademanager");
                    switch (params.tab) {
                        case "instruction":
                            store.userAccountStore.setTrademanagerContent({ selectedTab: TrademanagerTab.INSTRUCTION });
                            break;
                        case "download":
                            store.userAccountStore.setTrademanagerContent({ selectedTab: TrademanagerTab.DOWNLOAD });
                            break;
                        default:
                            break;
                    }
                    break;
                case "tmseller":
                    store.userAccountStore.setSelectedApp("tmseller");
                    switch (params.tab) {
                        case "instruction":
                            store.userAccountStore.setTMSellerContent({ selectedTab: TMSellerTab.INSTRUCTION });
                            break;
                        case "download":
                            store.userAccountStore.setTMSellerContent({ selectedTab: TMSellerTab.DOWNLOAD });
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }

        }
        console.log(params);
    });

    return (
        <>
            <div className='container'>
                <div className='user-account'>
                    <TopPanel />
                    <div className='user-account__content'>
                        <AppSelection />
                        <AppContent />
                    </div>
                </div>
            </div>
        </>
    );
});

export default ControlPanelPage;