import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './styles/normalize.css';
import './styles/index.scss';
import Main from './pages/Main/Main';
import Help from './pages/Help/Help';
import UserAccount from './pages/UserAccount/UserAccount';
import Unknown from './pages/Unknown/Unknown';
import TMSeller from './pages/Tmseller/TMSeller';
import "react-alice-carousel/lib/alice-carousel.css";
import Trademanager from './pages/Trademanager/Trademanager';
import AdditionalElementsWrapper from './globalComponents/AdditionalWrapper/AdditionalWrapper';
import CustomSoft from './pages/CustomSoft/CustomSoft';
import AddBalanceLoading from './pages/AddBalanceLoading/AddBalanceLoading';
import SuccessPayment from './pages/SuccessPayment/SuccessPayment';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const router = createBrowserRouter([
  {
    path: "/main*",
    element: <AdditionalElementsWrapper><Main /></AdditionalElementsWrapper>
  },
  {
    path: "/faq/*",
    element: <AdditionalElementsWrapper><Help /></AdditionalElementsWrapper>
  },
  {
    path: "/user-account/*",
    element: <AdditionalElementsWrapper><UserAccount /></AdditionalElementsWrapper>
  },
  {
    path: "/tmseller/*",
    element: <AdditionalElementsWrapper><TMSeller /></AdditionalElementsWrapper>
  },
  {
    path: "/trademanager/*",
    element: <AdditionalElementsWrapper><Trademanager /></AdditionalElementsWrapper>
  },
  {
    path: "*",
    element: <AdditionalElementsWrapper><Unknown /></AdditionalElementsWrapper>
  },
  {
    path: "/custom-soft",
    element: <AdditionalElementsWrapper><CustomSoft /></AdditionalElementsWrapper>
  },
  {
    path: "/add-balance",
    element: <AdditionalElementsWrapper><AddBalanceLoading /></AdditionalElementsWrapper>
  },
  {
    path: "/success-payment",
    element: <AdditionalElementsWrapper>< SuccessPayment /></AdditionalElementsWrapper>
  }
]);

root.render(
  <RouterProvider router={router} />
);