import scrollToSelector from '../../scripts/scrollToSelector';
import './Footer.scss';
import { observer } from "mobx-react";

const Footer = observer(() => {
    return (
        <div className='attic'>
            <div className='separator'></div>

            <div className='container'>
                <a href='/' className='header__logo'>
                    <img src='/images/logo-grey.svg'></img>
                </a>
                <div className='header__navbar'>
                    <a href='/main' className='navbar__link'>Главная</a>
                    <a className='navbar__link' onClick={() => {
                        if (!window.location.href.includes('/main')) {
                            window.location.replace(`/main?products`);
                        } else {
                            scrollToSelector("#root > div:nth-child(2) > div > div > div.product-section__content > div.title");
                        }
                    }}>Наши продукты</a>
                    <a href='/faq' className='navbar__link'>Помощь</a>
                </div>

            </div>

            <div className='separator'></div>
        </div>
    )
});

export default Footer;