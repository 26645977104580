import React, { FC } from 'react';
import { observer } from 'mobx-react';
import PageWrapper from '../../globalComponents/PageWrapper/PageWrapper';

const SuccessPayment: FC<{}> = observer((props) => {
    return (
        <PageWrapper>
            <div className='container'>
                <h1>Спасибо 💗</h1>
                <h2>Счет вашего аккаунта пополнен, если имеются вопросы, то поддержка всегда на связи 📞</h2>
            </div>
        </PageWrapper>
    );
});

export default SuccessPayment;