import React, { FC } from 'react';
import { observer } from 'mobx-react';
import PageWrapper from '../../globalComponents/PageWrapper/PageWrapper';
import IntroSection from './Sections/IntroSection/IntroSection';
import GallerySection from '../../globalComponents/GallerySection/GallerySection';
import AboutSection from './Sections/AboutSection/AboutSection';
import GuideSection from '../../globalComponents/GuideSection/GuideSection';
import FunctionalSection from './Sections/FunctionalSection/FunctionalSection';
import InfoSection from './Sections/InfoSection/InfoSection';
import HelpSection from './Sections/HelpSection/HelpSection';
import SafetySection from './Sections/SafetySection/SafetySection';
import FAQSection from '../../globalComponents/FAQSection/FAQSection';

const TMSeller: FC<{}> = observer((props) => {
    return (
        <PageWrapper>
            <IntroSection />
            <GallerySection title={"Скриншоты приложения"} images={["../images/tmseller/1.png", "../images/tmseller/2.png", "../images/tmseller/3.png"]} />
            <AboutSection />
            <FunctionalSection />
            <GuideSection links={[
                {
                    link: "https://google.com",
                    text: "Видео гайд по использованию",
                    backgroundImage: "/images/tmseller/1.png"
                },
                {
                    link: "https://google.com",
                    text: "Текстовый гайд по использованию",
                    backgroundImage: "/images/tmseller/2.png",
                    icon: "/images/guideblock-right.svg"
                },
                {
                    link: "https://google.com",
                    text: "Техническая документация (для кодеров и прошаренных ребят)",
                    backgroundImage: "/images/background-text-guide.jpg",
                    icon: "/images/guideblock-right.svg"
                }
            ]} />
            <InfoSection />
            <HelpSection />
            <SafetySection />
            <FAQSection />
        </PageWrapper>
    );
});

export default TMSeller;