import React, { FC, useState } from "react";
import "./Item.scss";

const Item: FC<{ question: string, answer: string }> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={isOpen ? "help-item-open" : "help-item"} >

            <div className="header" onClick={() => { setIsOpen(!isOpen) }}>
                <h3>{props.question}</h3>
                <img
                    src={isOpen ? "./images/arrow-down.png" : "./images/arrow-down.png"}
                ></img>
            </div>

            <div className="content">
                <p>{props.answer}</p>
            </div>

        </div>
    );
}

export default Item;