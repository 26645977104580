export function getUrlParams(url: string): { app: string; tab: string } | null {
    try {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);
        const app = params.get('app');
        const tab = params.get('tab');

        if (app && tab) {
            return {
                app,
                tab,
            };
        }
        return null;
    } catch (error) {
        console.error(`Invalid URL: ${url}`);
        return null;
    }
}