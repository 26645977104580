import React, { FC } from 'react';
import { observer } from 'mobx-react';

const Loading: FC<{}> = observer((props) => {
    return (
        <div className='loading-page'>
            <img className='loading-page__image' src="/images/SVG-Logo.gif" alt="" />
        </div>
    );
});

export default Loading;