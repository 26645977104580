import "./HelpSection.scss";

const HelpSection = () => {
    return (
        <div className="seller-help-section">
            <div className="container">
                <div className="left">
                    <h1>Вам всегда помогут</h1>
                    <p>
                        Наша поддержка ответит на любой возникший у вас вопрос. Да, конечно,
                        проблемы бывают, Steam иногда лагает очень сильно, но мы не унываем
                        и делаем все возможное для вашей стабильной работы
                    </p>
                </div>
                <div className="right"></div>
            </div>
        </div>
    );
}

export default HelpSection;