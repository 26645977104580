import React from "react";
import "./FAQSection.scss";

const FAQSection = () => {
    return (
        <div className="links-section">
            <div className="container">
                <div className="title">Задайте вопрос и следите за новостями</div>
                <div className="links">
                    <div className="link">
                        <div className="logo-container">
                            <img className="logo" src="/images/vk.svg" />
                        </div>

                        <div className="right">
                            <div className="title">
                                Мы <br /> вконтакте
                            </div>
                            <a
                                className="link__btn"
                                href={"https://vk.com/pinkest_dev"}
                            >
                                Перейти
                            </a>
                        </div>
                    </div>

                    <div className="link">
                        <div className="logo-container">
                            <img className="logo" src="/images/telegram.svg" />
                        </div>
                        <div className="right">
                            <div className="title">
                                Мы <br /> в telegram
                            </div>
                            <a
                                className="link__btn"
                                href={"https://t.me/pinkestdev"}
                            >
                                Перейти
                            </a>
                        </div>
                    </div>

                    <a className="link-gradient" href="/faq">
                        <span>FAQ</span>
                    </a>
                </div>

                <a className="link-gradient-long" href="/faq">
                    <span>FAQ</span>
                </a>

            </div>
        </div>
    );
}

export default FAQSection;
