import React from "react";
import "./Steam.scss";

function Steam() {
  return (
    <div className="custom-soft-steam">
      <div className="gradient"></div>
      <div className="container">
        <div className="left">
          <h1>
            Мы в первую очередь сконцентрированы на создании{" "}
            <span className="h1_gradient">софта для Steam</span>
          </h1>
          <p>это по нашей части.</p>
          <a
            className="work-btn"
          >
            <span>Я хочу с вами поработать!</span>
          </a>
        </div>
        <div className="right">
          <img className="" src="./images/steam-icon-big.svg"></img>
        </div>
      </div>
    </div>
  );
}

export default Steam;
