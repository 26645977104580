export enum UserAccountPage {
    "LOADING" = "LOADING",
    "LOGIN" = "LOGIN",
    "RESTORE_ACCESS" = "RESTORE_ACCESS",
    "REGISTRATION" = "REGISTRATION",
    "CONTROL_PANEL" = "CONTROL_PANEL",
    "ADD_BALANCE" = "ADD_BALANCE"
}

export enum TMSellerTab {
    "MY_BOTS" = "MY_BOTS",
    "DESCRIPTION" = "DESCRIPTION",
    "INSTRUCTION" = "INSTRUCTION",
    "ADD_BOT" = "ADD_BOT",
    "TRANSFER_BOT" = "TRANSFER_BOT",
    "DOWNLOAD" = "DOWNLOAD"
}

export enum TrademanagerTab {
    "DESCRIPTION" = "DESCRIPTION",
    "INSTRUCTION" = "INSTRUCTION",
    "DOWNLOAD" = "DOWNLOAD"
}