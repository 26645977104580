import React from "react";
import { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./CreatedProject.scss";

function CreatedProject() {
  const items = [
    <div className="custom-item" id="created-project-item-1">
      <div className="title">
        <img src="./images/Steam.svg"></img>
        <h1>Программа для быстрой продажи скинов в Steam</h1>
      </div>
    </div>,
    <div className="custom-item" id="created-project-item-2">
      <div className="title">
        <img src="./images/csmoney.svg"></img>
        <h1>Закупка выгодных предметов на cs.money и вывод их в Steam</h1>
      </div>
    </div>,
    <div className="custom-item" id="created-project-item-3">
      <div className="title">
        <h1>Софт для скупки выгодных предметов на polygon.gg</h1>
      </div>
    </div>,
    <div className="custom-item" id="created-project-item-4">
      <div className="mini-title">Может работать с несколькими аккаунтами</div>
      <div className="title">
        <img src="./images/Steam.svg"></img>
        <h1>Поиск скинов с интересным float на торговой площадке Steam</h1>
      </div>
    </div>,
    <div className="custom-item" id="created-project-item-5">
      <div className="title">
        <img src="./images/Steam.svg"></img>
        <h1>Отправка всего инвентаря CS:GO с множества аккаунтов на один</h1>
      </div>
    </div>,
    <div className="custom-item" id="created-project-item-6">
      <div className="title">
        <img src="./images/Chrome.png"></img>
        <h1>Расширение Chrome для анализа предметов на buff</h1>
      </div>
    </div>,
    <div className="custom-item" id="created-project-item-7">
      <div className="title">
        <img src="./images/resale.svg"></img>
        <h1>Массовая расстановка запросов на покупку на market.csgo.com</h1>
      </div>
    </div>,
  ];

  const responsive = {
    768: { items: 2 },
  };

  const carousel = useRef(null);

  return (
    <div className="custom-soft-created-project">
      <div className="container">
        <div className="info">
          <h1>Реализованные проекты</h1>
          <div className="sliders" key="btns">
            <div className="btn-left" onClick={(e) =>
              //@ts-ignore
              carousel?.current?.slidePrev(e)
            }></div>
            <div className="btn-right" onClick={(e) =>
              //@ts-ignore
              carousel?.current?.slideNext(e)
            }></div>
          </div>
        </div>

        <div className="slider">
          <AliceCarousel infinite={true} items={items} responsive={responsive} disableButtonsControls ref={carousel} />
        </div>


      </div>
    </div>
  );
}

export default CreatedProject;
