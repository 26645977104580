import { observable, action, makeObservable, toJS } from 'mobx';
import type { DepositMethodInterface, GeneralInfoInterface, TMSellerBotInterface, TMSellerContentInterface, TMSellerInfoInterface, TrademanagerContentInterface, TrademanagerInfoInterface } from '../interfaces/storeInterfaces';
import { TMSellerTab, TrademanagerTab, UserAccountPage } from '../enums/storeEnums';

class User {
    @observable email: string = '';
    @observable balance: number = 0;
    @observable tmsellerBots: TMSellerBotInterface[] = [];

    constructor(email: string, balance: number) {
        this.email = email;
        this.balance = balance;

        makeObservable(this);
    }

    @action
    setTmsellerBots(newTmsellerBots: TMSellerBotInterface[]) {
        this.tmsellerBots = newTmsellerBots;
    }

    @action
    setBalance(newBalance: number) {
        this.balance = newBalance;
    }
}

class UserAccountStore {
    @observable usdRate: number | null = null;
    @observable user: User | null = null;
    @observable depositMethods: DepositMethodInterface[] | null = null;
    @observable generalInfo: GeneralInfoInterface | null = null;
    @observable tmsellerInfo: TMSellerInfoInterface | null = null;
    @observable trademanagerInfo: TrademanagerInfoInterface | null = null;
    @observable currentUserAccountPage: string = UserAccountPage.LOADING;
    @observable tmsellerContent: TMSellerContentInterface = {
        selectedTab: TMSellerTab.ADD_BOT
    }
    @observable trademanagerContent: TrademanagerContentInterface = {
        selectedTab: TrademanagerTab.DOWNLOAD
    }
    @observable selectedApp: "tmseller" | "trademanager" = "tmseller";

    constructor() {
        makeObservable(this);
    }

    @action setUsdRate(rate: number) {
        this.usdRate = rate;
    }

    @action setUser(email: string, balance: number) {
        this.user = new User(email, balance);
    }

    @action setGeneralInfo(newGeneralInfo: GeneralInfoInterface) {
        this.generalInfo = newGeneralInfo;
    }

    @action setTMSellerInfo(newTMSellerInfo: TMSellerInfoInterface) {
        this.tmsellerInfo = newTMSellerInfo;
    }

    getTMSellerInfo() {
        return toJS(this.tmsellerInfo);
    }

    @action setDepositMethods(newDepositMethods: DepositMethodInterface[]) {
        this.depositMethods = newDepositMethods;
    }

    @action setTrademanagerInfo(newTrademanagerInfo: TrademanagerInfoInterface) {
        this.trademanagerInfo = newTrademanagerInfo;
    }

    @action setCurrentAccountPage(newPage: UserAccountPage) {
        console.log(this.currentUserAccountPage);
        this.currentUserAccountPage = newPage;
    }

    @action setSelectedApp(newSelectedApp: "tmseller" | "trademanager") {
        this.selectedApp = newSelectedApp;
    }

    @action setTMSellerContent(newTMSellerContent: { [K in keyof TMSellerContentInterface]?: TMSellerContentInterface[K] }) {
        this.tmsellerContent = { ...this.tmsellerContent, ...newTMSellerContent };
    }

    @action setTrademanagerContent(newTrademanagerContent: { [K in keyof TrademanagerContentInterface]?: TrademanagerContentInterface[K] }) {
        this.trademanagerContent = { ...this.trademanagerContent, ...newTrademanagerContent };
    }
}

export default UserAccountStore;