import React from "react";
import "./AboutSection.scss";

const AboutSection = () => {
    return (
        <div className="seller-about-section">
            <div className="container">
                <div className="left"></div>
                <div className="right">
                    <h1>О приложении 🔎</h1>
                    <p>
                        Программа была разработана реальными трейдерами для точно таких же трейдеров. Мы сами пользуемся TMSeller, поэтому можем с уверенностью рекомендовать его и вам.
                        <br />
                        Описание главных функций ниже 👇
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;