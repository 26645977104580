import React, { FC } from 'react';
import { observer } from 'mobx-react';
import store from '../../../../../../store/store';

const AppSelection: FC<{}> = observer((props) => {
    return (
        <>
            <div className='user-account__left-content'>
                <h3 className='user-account__left-content-title'>Приложения</h3>
                <div className='user-account__apps'>
                    <div className={`user-account__app-title ${store.userAccountStore.selectedApp == "tmseller" ? "user-account__app-title--active" : ""}`} onClick={() => {
                        store.userAccountStore.setSelectedApp("tmseller");
                    }}>
                        <img src='./images/tmseller-mini.png'></img>
                        <span>TM Seller</span>
                    </div>
                </div>
                <div className='user-account__apps'>
                    <div className={`user-account__app-title ${store.userAccountStore.selectedApp == "trademanager" ? "user-account__app-title--active" : ""}`} onClick={() => {
                        store.userAccountStore.setSelectedApp("trademanager");
                    }}>
                        <img src='./images/trademanager-mini.png'></img>
                        <span>Trademanager</span>
                    </div>
                </div>
            </div>
        </>
    );
});

export default AppSelection;