import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const PageWrapper: FC<{
    children: ReactNode
}> = observer(({ children }) => {
    return (
        <>
            <Header />
            <div style={{ minHeight: 'calc(100vh - 284px)', paddingTop: '50px' }}>
                {
                    children
                }
            </div>
            <Footer />
        </>
    );
});

export default PageWrapper;