import React, { createRef, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./AdvantagesSection.scss";

export default function AdvantagesSection() {
  const items = [
    <div className="slider__slide" data-value="1">
      <div
        className="slider_slide-content"
        style={{
          background: "no-repeat url('./images/advantages-1.png')",
        }}
      >
        <h1>Трейдеры со стажем</h1>
        <span>
          Мы зарабатываем на рынке игровых предметов Steam уже не первый год и имем хорошие познания в том, как он устроен, поэтому наш софт действительно приносит прибыль
        </span>
      </div>
    </div>,
    <div className="slider__slide" data-value="2">
      <div
        className="slider_slide-content"
        style={{
          background: "no-repeat url('./images/advantages-2.png')",
        }}
      >
        <h1>Наш софт облегчит вам жизнь</h1>
        <span>
          Мы убеждены, что как только появлятеся рутинная работа - её нужно автоматизировать. Ведь время нужно тратить на важные и интересные вещи
        </span>
      </div>
    </div>,
    <div className="slider__slide" data-value="3">
      <div
        className="slider_slide-content"
        style={{
          background: "no-repeat url('./images/advantages-3.png')",
        }}
      >
        <h1>Поддерживаем и дорабатываем</h1>
        <span>
          Используя наш софт вы можете быть уверены, что он не устарет. Даже если Steam что-то поменяет в своей работе, то мы быстро перепишем код
        </span>
      </div>
    </div>,
    <div className="slider__slide" data-value="4">
      <div
        className="slider_slide-content"
        style={{
          background: "no-repeat url('./images/advantages-4.png')",
        }}
      >
        <h1>Вы не останетесь без внимания</h1>
        <span>
          Мы всегда отвечаем на ваши сообщения. Если вдруг возникла проблема, то мы поможем вам её решить в ближайшее время
        </span>
      </div>
    </div>,
    <div className="slider__slide" data-value="5">
      <div
        className="slider_slide-content"
        style={{
          background: "no-repeat url('./images/advantages-5.png')",
        }}
      >
        <h1>Наше коммьюнити растет</h1>
        <span>
          Пока мы не такие популярные, но возможно скоро это изменится. Подписывайся на наши соц. сети и следи за новостями
        </span>
      </div>
    </div>,
  ];

  const responsive = {
    400: { items: 1 },
    700: { items: 2 },
    1060: { items: 3 },
    1240: { items: 4 },
  };

  const carousel = useRef(null);

  return (
    <section className="advantages-section">
      <div className="container">
        <div className="advantages-section__info">
          <div className="text">
            Кто мы такие и почему нам можно довериться
            <br />
          </div>
          <div className="sliders" key="btns">
            <div className="btn-left" onClick={(e) =>
              //@ts-ignore
              carousel?.current?.slidePrev(e)}></div>
            <div className="btn-right" onClick={(e) =>
              //@ts-ignore
              carousel?.current?.slideNext(e)}></div>
          </div>
        </div>

        <div className="advantages-section__slider">
          <AliceCarousel
            infinite={true}
            items={items}
            responsive={responsive}
            disableButtonsControls
            ref={carousel}
          />
        </div>
      </div>
    </section>
  );
}
