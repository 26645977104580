import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import "./Header.scss";
import scrollToSelector from "../../scripts/scrollToSelector";
import store from "../../store/store";

const Header = observer(() => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (window.location.href.includes('/main?products')) {
            scrollToSelector("#root > div:nth-child(2) > div > div > div.product-section__content > div.title");
        }
    }, []);

    return (
        <header>
            <div className="container">
                <a href="/main" className="header__logo">
                    <img src="/images/logo.svg"></img>
                </a>
                <div className="header__navbar">
                    <a
                        href="/main"
                        className={`navbar__link ${window.location.href.includes('/main') ? "active" : ""}`}
                    >
                        Главная
                    </a>
                    <a
                        style={{ cursor: "pointer" }}

                        className="navbar__link"
                        onClick={() => {
                            if (!window.location.href.includes('/main')) {
                                window.location.replace(`/main?products`);
                            } else {
                                scrollToSelector("#root > div:nth-child(2) > div > div > div.product-section__content > div.title");
                            }
                        }}
                    >
                        Наши Продукты
                    </a>

                    <a href="/faq" className={`navbar__link ${window.location.href.includes('/faq') ? "active" : ""}`}>
                        Помощь
                    </a>
                </div>

                <div
                    onClick={() => {
                        setIsMenuOpen(!isMenuOpen);
                    }}
                    className={
                        isMenuOpen ? "header__mobile-btn active" : "header__mobile-btn"
                    }
                >
                    <span></span>
                </div>

                <div
                    className={
                        isMenuOpen ? "header__mobile-menu active" : "header__mobile-menu"
                    }
                >
                    <div className="header-menu__links">
                        <a href="/main" className="navbar__link">
                            Главная
                        </a>
                        <a href="/user-account" className="navbar__link">
                            {store.userAccountStore.user ? <><img src="/images/person.svg" alt="" /> {store.userAccountStore.user.email}</> : "Личный  кабинет"}
                        </a>
                        <a
                            className="navbar__link"
                            href="/main?products"
                            onClick={() => {
                                setIsMenuOpen(false);
                                scrollToSelector("#root > div:nth-child(2) > div > div > div.product-section__content > div.title");
                            }}
                        >
                            Продукты
                        </a>

                        <a href="/faq" className="navbar__link">
                            Помощь
                        </a>
                    </div>
                    <div className="header__actions"></div>
                </div>

                <div className="header__actions">
                    <a href="https://t.me/pinkestdev" className="actions__link-btn">
                        <img src="/images/logo-telegram.svg"></img>
                    </a>
                    <a href="https://vk.com/pinkest_dev" className="actions__link-btn">
                        <img src="/images/logo-vk.svg"></img>
                    </a>
                    <a
                        href="/user-account"
                        className={`navbar__link ${window.location.href.includes('user-account') ? 'active' : ''}`}
                    >
                        {store.userAccountStore.user ? <><img src="/images/person.svg" alt="" /> {store.userAccountStore.user.email}</> : "Личный  кабинет"}
                    </a>
                </div>
            </div>
        </header>
    );
});

export default Header;