import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import store from '../../../../../../../store/store';
import { TMSellerTab } from '../../../../../../../enums/storeEnums';
import "./TMSellerContent.scss";
import date from 'date-and-time';
import LinkScreen from '../LinkScreen/LinkScreen';
import {
    createTmSellerBot,
    getAccessToken,
    getMyTmSellerBots, login,
    transferOldTmSellerBot,
    host,
    downloadPanel,
    getBalance
} from '../../../../../../../api/api';
import PriceTag from '../../../../../../../globalComponents/PriceTag/PriceTag';

const TMSellerContent: FC = observer(() => {
    const [newSubName, setNewSubName] = useState("");
    const [monthAmount, setMonthAmount] = useState(1);
    const [transferBotName, setTransferBotName] = useState("");
    const [transferBotPassword, setTransferBotPassword] = useState("");
    const [wrongEnterNewSubName, setWrongEnterNewSubName] = useState(false);
    const [wrongEnterTransferBotName, setWrongEnterTransferBotName] = useState(false);
    const [wrongEnterTransferBotPassword, setWrongEnterTransferBotPassword] = useState(false);
    const newSubNameValidation = (newSubName: string) => {
        let newSubNameValid = newSubName
            .toLowerCase()
            .match(
                /^[^\d\W][\w\d]{1,}$/
            ) === null
        setWrongEnterNewSubName(newSubNameValid)
        return !newSubNameValid
    }
    const transferBotNameValidation = (transferBotName: string) => {
        let transferBotNameValid = transferBotName
            .match(
                /^[^\d\W][^\s]{1,}$/
            ) === null
        setWrongEnterTransferBotName(transferBotNameValid)
        return !transferBotNameValid
    }
    const transferBotPasswordValidation = (transferBotPassword: string) => {
        let transferBotPasswordValid = transferBotPassword
            .match(
                /(?=.*\d)(?=.*[a-z]).{6,}/
            ) === null
        setWrongEnterTransferBotPassword(transferBotPasswordValid)
        return !transferBotPasswordValid
    }


    const updateBots = () => {
        getMyTmSellerBots((errorText, bots) => {
            if (!errorText && bots)
                store.userAccountStore.user?.setTmsellerBots(bots);
        });
    }

    const updateBalance = () => {
        getBalance((errorText, data) => {
            if (!errorText && data) {
                store.userAccountStore.user?.setBalance(data.balance);
            }
        });
    }

    switch (store.userAccountStore.tmsellerContent.selectedTab) {
        case TMSellerTab.MY_BOTS:
            updateBalance();
            return <div className='content-screen'>
                <div className='tmseller-bots'>
                    <div className='tmseller-bots__header'>
                        <div className='tmseller-bots__status-header'>
                            Статус и имя бота:
                        </div>
                        <div className='tmseller-bots__subscribe-header'>
                            Подписка до:
                        </div>
                        <div className='content-screen__refresh-btn' onClick={() => {
                            getMyTmSellerBots((errorText, tmsellerBots) => {
                                if (errorText) {
                                    if (errorText == 'не авторизован') {
                                        getAccessToken(() => {
                                        });
                                    }
                                } else {
                                    store.userAccountStore.user?.setTmsellerBots(tmsellerBots!);
                                }
                            });
                        }}>
                            <img src="/images/refresh.svg" alt="" />
                        </div>
                    </div>
                    <div className='tmseller-bots__content'>
                        {
                            !store.userAccountStore.user || !store.userAccountStore.user.tmsellerBots ?
                                <>Боты не загружены</>
                                : store.userAccountStore.user.tmsellerBots.length == 0 ?
                                    <>Ботов нет</>
                                    : store.userAccountStore.user.tmsellerBots.map((b, i) =>
                                        <div key={i} className="tmseller-bots__card-bot">
                                            <div className="tmseller-bots__name-block">
                                                <div
                                                    className={`light-indicator ${b.isOnline ? 'light-indicator--green' : 'light-indicator--red'}`}></div>
                                                <h3 className="tmseller-bots__name">{b.name}</h3>
                                            </div>

                                            <div className="tmseller-bots__subscribe-block">
                                                {/* <h4 className="tmseller-bots__subscribe">подписка до:</h4> */}
                                                <h4 className="tmseller-bots__subscribe-date">
                                                    {
                                                        date.format(new Date(b.expiry_date), "DD.MM.YYYY hh:mm")
                                                    }
                                                </h4>
                                            </div>
                                            <div className="tmseller-bots__prolong-button-wrapper"
                                                onClick={() => {
                                                    store.userAccountStore.setTMSellerContent({
                                                        selectedTab: TMSellerTab.ADD_BOT
                                                    });
                                                    setNewSubName(b.name)
                                                }}
                                            >
                                                <div className="tmseller-bots__prolong-button">
                                                    <h3>Продлить</h3>
                                                </div>
                                            </div>
                                        </div>
                                    )
                        }
                    </div>
                </div>
            </div>;
        case TMSellerTab.DESCRIPTION:
            updateBalance();
            return <LinkScreen title={'Описание функционала TMSeller на сайте'} buttonText={'Открыть'}
                img={'/images/tmseller/tm-logo.svg'} link={`${host}/tmseller`} />;
        case TMSellerTab.DOWNLOAD:
            updateBalance();
            return <LinkScreen title={'Нажмите накнопку и дождитесь окончания загрузки TM Seller (CSGO)'} buttonText={'Скачать'}
                img={'/images/tmseller/tm-logo.svg'} link={`${host}/tmseller/download-panel`} />;
        case TMSellerTab.INSTRUCTION:
            updateBalance();
            return <LinkScreen title={'Инструкция по настройке и работе с TMSeller в notion'} buttonText={'Открыть'}
                img={'/images/tmseller/notion.svg'} link={'https://factual-sail-6ac.notion.site/TM-Seller-df6540f130a546b3a7efdefa9221419c'} />
        case TMSellerTab.ADD_BOT:
            updateBalance();
            return <div className='content-screen'>
                <div className='tmseller-add-bot'>
                    <div className='tmseller-add-bot__form'>
                        <h2 className='tmseller-add-bot__title'>Приобрести нового бота или продлить подписку</h2>
                        <div className='tmseller-add-bot__input-container'>
                            <span className='tmseller-add-bot__input-title'>Имя бота</span>
                            <div
                                className={!wrongEnterNewSubName ? 'tmseller-add-bot__input' : "tmseller-add-bot__wrong-input"}>
                                <input placeholder='Начните вводить...' type="text" value={newSubName}
                                    onChange={(e) => {
                                        setNewSubName(e.target.value);
                                    }} />
                                <div className='tmseller-add-bot__input-text'>{
                                    store.userAccountStore.user?.tmsellerBots.map(b => b.name).includes(newSubName) ? "Продлить" : "Новый"
                                }</div>
                            </div>
                        </div>
                        <div className='tmseller-add-bot__input-container'>
                            <span className='tmseller-add-bot__input-title'>Количество месяцев</span>
                            <div className='tmseller-add-bot__input'>
                                <input placeholder='Начните вводить...' type="text" value={monthAmount}
                                    onChange={(e) => {
                                        if (Number(e.target.value) >= 0)
                                            setMonthAmount(Number(e.target.value));
                                    }} />
                                <div className='tmseller-add-bot__input-text'>{
                                    store.userAccountStore.user?.tmsellerBots.filter(b => b.name == newSubName)[0] ? date.format(new Date(Number(new Date(store.userAccountStore.user?.tmsellerBots.filter(b => b.name == newSubName)[0].expiry_date)) + monthAmount * 30 * 24 * 60 * 60 * 1000), 'DD.MM.YYYY') : date.format(new Date(Number(new Date()) + monthAmount * 30 * 24 * 60 * 60 * 1000), 'DD.MM.YYYY')
                                }</div>
                            </div>
                        </div>
                        <PriceTag prefix='Итого: '
                            usdPrice={monthAmount * store.userAccountStore.tmsellerInfo?.price!} />
                        <button
                            className={
                                store.userAccountStore.user?.balance !== undefined ?
                                    store.userAccountStore.user?.balance > store.userAccountStore.tmsellerInfo?.price! * monthAmount ?
                                        "basic-button basic-button--gradient" :
                                        "basic-button tmseller__none-balance-button" :
                                    "basic-button tmseller__error-balance-button"
                            }
                            onClick={() => {
                                if (newSubNameValidation(newSubName)) {
                                    createTmSellerBot(newSubName, monthAmount, (errorText) => {
                                        if (errorText !== null) {
                                            store.pageStore.openPrompt("error", "Не удалось добавить бота " + newSubName, errorText);
                                        } else {
                                            store.pageStore.openPrompt("success", "Бот " + newSubName + " успешно добавлен", "", "Отлично!");
                                            updateBots();
                                            updateBalance();
                                        }
                                    });
                                } else {
                                    store.pageStore.openPrompt("error", "Некорректные данные", "Неверное имя бота: минимум 2 латинские буквы, имя не должно начинаться с цифры");
                                }
                            }}
                        >
                            {
                                store.userAccountStore.user?.balance !== undefined ?
                                    store.userAccountStore.user?.balance > store.userAccountStore.tmsellerInfo?.price! * monthAmount ?
                                        store.userAccountStore.user?.tmsellerBots.map(b => b.name).includes(newSubName) ?
                                            "Продлить бота" :
                                            "Добавить нового бота" :
                                        "Недостаточно средств" :
                                    "Ошибка средств"
                            }
                        </button>
                    </div>
                    <div className='tmseller-add-bot__div'></div>
                    <div className='tmseller-add-bot__faq'>
                        <h3 className='tmseller-add-bot__title'>Часто задаваемые вопросы</h3>
                        <div className='tmseller-add-bot__questions'>
                            {Object.keys(store.userAccountStore.tmsellerInfo?.transferFAQ || {}).map((el, i) => {
                                return <a key={i} className='tmseller-add-bot__question' onClick={() => {
                                    store.pageStore.openModalWindow(store.userAccountStore.tmsellerInfo?.transferFAQ[el]);
                                }}>{el}</a>
                            })}
                        </div>
                    </div>
                </div>
            </div>;
        case TMSellerTab.TRANSFER_BOT:
            updateBalance();
            return <div className='content-screen'>
                <div className='tmseller-add-bot'>
                    <div className='tmseller-add-bot__form'>
                        <h2 className='tmseller-add-bot__title'>Перенести бота из старой системы</h2>
                        <div className='tmseller-add-bot__input-container'>
                            <span className='tmseller-add-bot__input-title'>Имя старого бота</span>
                            <div
                                className={'tmseller-add-bot__input'}>
                                <input placeholder='Начните вводить...' type="text" value={transferBotName}
                                    onChange={(e) => {
                                        setTransferBotName(e.target.value);
                                    }} />
                            </div>
                        </div>
                        <div className='tmseller-add-bot__input-container'>
                            <span className='tmseller-add-bot__input-title'>Пароль старого бота</span>
                            <div
                                className={'tmseller-add-bot__input'}>
                                <input placeholder='Начните вводить...' type="password" value={transferBotPassword}
                                    onChange={(e) => {
                                        setTransferBotPassword(e.target.value);
                                    }} />
                            </div>
                        </div>
                        <button
                            className={
                                store.userAccountStore.user?.balance !== undefined ?
                                    store.userAccountStore.user?.balance > store.userAccountStore.tmsellerInfo?.price! * monthAmount ?
                                        "basic-button basic-button--gradient" :
                                        "basic-button tmseller__none-balance-button" :
                                    "basic-button tmseller__error-balance-button"
                            }
                            onClick={() => {
                                transferOldTmSellerBot(transferBotName, transferBotPassword, (errorText) => {
                                    if (errorText !== null) {
                                        store.pageStore.openPrompt("error", "Не удалось перенести бота " + transferBotName, errorText);
                                    } else {
                                        store.pageStore.openPrompt("success", "Бот " + transferBotName + " успешно перенесен", "", "Отлично!");
                                    }
                                });
                            }}
                        >
                            {
                                store.userAccountStore.user?.balance !== undefined ?
                                    store.userAccountStore.user?.balance > store.userAccountStore.tmsellerInfo?.price! * monthAmount ?
                                        "Перенести бота" :
                                        "Недостаточно средств" :
                                    "Ошибка средств"
                            }
                        </button>
                    </div>
                    <div className='tmseller-add-bot__div'></div>
                    <div className='tmseller-add-bot__faq'>
                        <h3 className='tmseller-add-bot__title'>Часто задаваемые вопросы</h3>
                        <div className='tmseller-add-bot__questions'>
                            {Object.keys(store.userAccountStore.tmsellerInfo?.addNewFAQ || {}).map((el) => {
                                return <a className='tmseller-add-bot__question' onClick={() => {
                                    store.pageStore.openModalWindow(store.userAccountStore.tmsellerInfo?.addNewFAQ[el]);
                                }}>{el}</a>
                            })}
                        </div>
                    </div>
                </div>
            </div>;
        default:
            return <>Такой вкладки нет!</>;
    }
});
export default TMSellerContent;