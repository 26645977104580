import React from "react";
import "./Team.scss";

function Team() {
  return (
    <div className="custom-soft-team">
      <div className="container">
        <div className="left">{/* <img src="./images/task.png"></img> */}</div>
        <div className="right">
          <h1>Команда pinkest с этим разберется!</h1>
          <p>и заодно мы подумаем над защитой исходного кода</p>
          <a className="team-work-btn">
            <span className="gradient-text">
              Я хочу с вами поработать!
            </span>
          </a>
        </div>
      </div>
    </div >
  );
}

export default Team;
