import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PageWrapper from '../../globalComponents/PageWrapper/PageWrapper';
import "./AddBalanceLoading.css";
import store from '../../store/store';
import { cryptoCloudDeposit, userAccountHost, yoomoneyBuildForm, yoomoneyCreateOrder } from '../../api/api';
import openInNewTab from '../../scripts/openInNewTab';

type ParsedParams = {
    method?: string;
    value?: string;
};

function parseUrl(url: string): ParsedParams {
    const paramsPattern = /[?&]+([^=&]+)=([^&]*)/g;
    let params: { [key: string]: string } = {}, tokens;

    while (tokens = paramsPattern.exec(url)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return {
        method: params['method'],
        value: params['value']
    };
}


const AddBalanceLoading: FC<{}> = observer((props) => {
    const params = parseUrl(window.location.toString());
    const [error, setError] = useState("");

    useEffect(() => {
        if (!params.method || !params.value) return;

        const methodData = store.userAccountStore.depositMethods?.find((el) => el.id == params.method);
        console.log(methodData, store.userAccountStore.depositMethods);

        if (!methodData) return;
        if (methodData.type == "yoomoney") {
            return yoomoneyCreateOrder(params.value, (errorText, orderId) => {
                if (errorText) {
                    setError(errorText);
                }
                if (!errorText && orderId) {
                    window.location.href = `${userAccountHost}/user/yoomoney-build-form/${orderId}`;
                } else {
                    setError(`Неизвестная ошибка. Попробуйте еще раз`);
                }
            });
        }
        if (methodData.type == "cryptoCloud") {
            return cryptoCloudDeposit(params.value, (errorText, url) => {
                if (!errorText) {
                    if (url)
                        window.location.href = url;
                    else setError(`Неизвестная ошибка. Попробуйте еще раз`);
                } else {
                    setError(errorText);
                }
            });
        }
    }, [store.userAccountStore.depositMethods]);

    if (error) {
        return (
            <PageWrapper>
                <div className='add-balance-loading'>
                    <div className='container'>
                        <h1>Ошибка</h1>
                        <h2>{error}. Попробуйте пополнить баланс еще раз. По всем вопросам пишите в поддержку</h2>
                    </div>
                </div>
            </PageWrapper>
        );
    }

    if (params.method && params.value)
        return (
            <PageWrapper>
                <div className='add-balance-loading'>
                    <div className='container'>
                        <img src="/images/SVG-Logo.gif" alt="" className='add-balance-loading__img' />
                        <h2>Создаем заявку на пополнение...</h2>
                        <div>Способ пополнения: {params.method}</div>
                        <div>Сумма пополнения: {params.value}$ ({(store.userAccountStore.usdRate! * Number(params.value)).toFixed(2)} руб.)</div>
                    </div>
                </div>
            </PageWrapper>
        );

    return <PageWrapper>
        <div className='add-balance-loading'>
            <div className='container'>
                <h1>Ошибка</h1>
                <h2>Метод пополнения баланса или значение не задано. Попробуйте создать заявку на пополнение еще раз</h2>
            </div>
        </div>
    </PageWrapper>;
});

export default AddBalanceLoading;