import React, { FC } from 'react';
import { observer } from 'mobx-react';
import PageWrapper from '../../globalComponents/PageWrapper/PageWrapper';
import IntroSection from './Sections/IntroSection/IntroSectrion';
import ProductSection from './Sections/ProductSection/ProductSection';
import AdvantagesSection from './Sections/AdvantagesSection/AdvantagesSection';
import LearnMoreSection from './Sections/LearnMoreSection/LearnMoreSection';

const Main: FC<{}> = observer((props) => {
    return (
        <>
            <PageWrapper>
                <IntroSection></IntroSection>
                <AdvantagesSection />
                <LearnMoreSection />
                <ProductSection></ProductSection>
            </PageWrapper>
        </>
    );
});

export default Main;