import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import LoginPage from './Pages/LoginPage/LoginPage';
import PageWrapper from '../../globalComponents/PageWrapper/PageWrapper';
import store from '../../store/store';
import { UserAccountPage } from '../../enums/storeEnums';
import RegistrationPage from './Pages/RegistrationPage/RegistrationPage';
import { getAccessToken } from '../../api/api';
import LoadingPage from './Pages/LoadingPage/LoadingPage';
import ControlPanelPage from './Pages/ControlPanelPage/ControlPanelPage';
import AddBalancePage from './Pages/AddBalancePage/AddBalancePage';
import Header from '../../globalComponents/Header/Header';
import RestorePage from "./Pages/RestorePage/RestorePage";

const UserAccount = observer(() => {
    return <>
        <Header />
        <Page />
    </>

});

const Page = observer(() => {
    switch (store.userAccountStore.currentUserAccountPage) {
        case UserAccountPage.LOADING:
            return <LoadingPage />;
        case UserAccountPage.LOGIN:
            return <LoginPage />;
        case UserAccountPage.RESTORE_ACCESS:
            return <RestorePage />;
        case UserAccountPage.REGISTRATION:
            return <RegistrationPage />;
        case UserAccountPage.CONTROL_PANEL:
            return <ControlPanelPage />;
        case UserAccountPage.ADD_BALANCE:
            return <AddBalancePage />;
        default:
            return <></>;
    }
});

export default UserAccount;