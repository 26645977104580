import React, { FC } from "react";
import "./GuideSection.scss";

const GuideSection: FC<{
    links: {
        link: string,
        text: string,
        backgroundImage?: string,
        icon?: string
    }[]
}> = ({ links }) => {
    return (
        <div className="guide-section">
            <div className="container">
                {
                    links.map((link) => {
                        return <div className="guide-section__link-wrapper"
                            style={{
                                backgroundImage: `url(${link.backgroundImage ? link.backgroundImage : "/images/guideblock_left.png"})`
                            }} onClick={() => { }}>
                            <div className="guide-section__link-text-container" >
                                <h3 className="guide-block__link-text">{link.text}</h3>
                                <img src={link.icon ? link.icon : "/images/guideblock-left.svg"} alt="" />
                            </div>
                        </div>;
                    })
                }
            </div>
        </div>
    );
}

export default GuideSection;