import React, { FC } from 'react';
import { observer } from 'mobx-react';
import store from "../../../../store/store";
import { UserAccountPage } from "../../../../enums/storeEnums";
import { login } from "../../../../api/api";
import "./RestorePage.scss";


const RestorePage: FC<{}> = observer((props) => {
    return (
        <div className="container">
            <div className='restore-block'>
                <img src="/images/user-account/account_restore.svg" />
                <h2 className="restore-block__title">Восстановление аккаунтов осуществляется через поддержку</h2>
                <h3 className="restore-block__subtitle">Напишите нам, поможем восстановить ваш аккаунт</h3>
                <div className="restore-block__contacts">
                    <div className="restore-block__contact">
                        <img className="restore-block__img" src="/images/logo-vk.svg" />
                        <h3 className="restore-block__contact-title">Мы вконтакте</h3>
                        <a className="restore-block__contact-button" href="https://vk.com/pinkest_dev">
                            <h3>Перейти</h3>
                        </a>
                    </div>
                    <div className="restore-block__contact">
                        <img className="restore-block__img" src="/images/logo-telegram.svg" />
                        <h3 className="restore-block__contact-title">Телеграм канал</h3>
                        <a className="restore-block__contact-button" href="https://t.me/pinkestdev">
                            <h3>Перейти</h3>
                        </a>
                    </div>
                </div>

                <div className="restore-block__back-button-wrapper" onClick={() => {
                    store.userAccountStore.setCurrentAccountPage(UserAccountPage.LOGIN);
                }}>
                    <div className="restore-block__back-button">
                        <h3>Назад к авторизации</h3>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default RestorePage;