import React from "react";
import "./AppSection.scss";

const AppSection = () => {
    return (
        <div className="app-section">
            <div className="gradient"></div>
            <div className="container">
                <h1>Это приложение необходимо вам если:</h1>
                <div className="list">
                    <div className="item">
                        <h4>Вы трейдите в Steam с нескольких аккаунтов</h4>
                    </div>
                    <div className="item">
                        <h4>Вы инвестируете в предметы на торговой площадке и хотите минимизировать риски от возможных банов со стороны Steam</h4>
                    </div>
                    <div className="item">
                        <h4>Вам просто нужно иметь под рукой много браузеров, запускаемых с разных прокси серверов</h4>
                    </div>

                </div>
                <div className="list" style={{ marginTop: '20px' }}>
                    <div className="item">
                        <h4>Вы фармите кейсы</h4>
                    </div>
                    <div className="item">
                        <h4>Вам важна безопасность</h4>
                    </div>
                    <div className="item">
                        <h4>Вы хотите легко и без лишнего гемора обмениваться аккаунтами с друзьями</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppSection;