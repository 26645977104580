import { makeObservable, observable } from 'mobx';
import UserAccountStore from './UserAccountStore';
import PageStore from './PageStore';
import { getAccessToken, getBalance, getDepositMethods, getTmsellerInfo, getUsdRate } from '../api/api';
import { UserAccountPage } from '../enums/storeEnums';

class Store {
    @observable
    pageStore: PageStore;
    @observable
    userAccountStore: UserAccountStore;

    constructor() {
        this.userAccountStore = new UserAccountStore();
        this.pageStore = new PageStore();

        makeObservable(this);

        getUsdRate((errorText, usdRate) => {
            if (usdRate)
                this.userAccountStore.usdRate = usdRate;
        });

        getTmsellerInfo((errorText, tmsellerInfo) => {
            if (!errorText && tmsellerInfo) {
                this.userAccountStore.setTMSellerInfo(tmsellerInfo);
                console.log(tmsellerInfo);
                this.userAccountStore.setGeneralInfo({ chatLink: tmsellerInfo.chat_link });
            }
        });

        getDepositMethods((errorText, depositMethods) => {
            if (!errorText && depositMethods) {
                this.userAccountStore.setDepositMethods(depositMethods);
            }
        });

        getBalance((errorText, data) => {
            if (errorText) {
                console.log(errorText);
                if (errorText == "не авторизован")
                    getAccessToken((errorText) => {
                        if (!errorText) {
                            this.userAccountStore.setCurrentAccountPage(UserAccountPage.CONTROL_PANEL);
                        } else {
                            this.userAccountStore.setCurrentAccountPage(UserAccountPage.LOGIN);
                        }
                    });
            } else {
                if (data?.email) {
                    this.userAccountStore.setUser(data?.email, data?.balance);
                    this.userAccountStore.setCurrentAccountPage(UserAccountPage.CONTROL_PANEL);
                }
            }
        });
    }
}

const store = new Store();

export default store;