import React from "react";
import "./IntroSection.scss";

function IntroSection() {
  return (
    <div className="custom-soft-intro-section">
      <div className="container">
        <div className="left">
          <h1>ЛЮБОЙ СОФТ ПОД ЗАКАЗ</h1>
          <p>
            Давно хотели автоматизировать какой-то рутинный процесс? Тогда эта
            услуга для вас!
          </p>
          <div className="list">
            <div className="item">
              <img src="./images/key.svg" className="icon"></img>
              <h1>Запарсим любой сайт, автоматизируем все что угодно</h1>
            </div>
            <div className="item">
              <img src="./images/basketball.svg" className="icon"></img>
              <h1>Можем сделать сайт или расширение для браузера</h1>
            </div>
            <div className="item">
              <img src="./images/Desktop.svg" className="icon"></img>
              <h1>Делаем и desktop приложения</h1>
            </div>
          </div>
          <div className="price">
            <h1>от 7000 рублей</h1>
            <p></p>
            {/* <a onClick={()=>{openInNewTab('https://vk.me/pinkest_dev')}} className="buy-btn">
              <span>Написать нам</span>
            </a> */}
            <a className="buy-btn">
              <span>Написать нам</span>
            </a>
          </div>
        </div>
        <div className="right">
          <div className="image"></div>
          <div className="price">
            <h1>от 7000 рублей</h1>
            <p></p>
            <a className="buy-btn">
              <span>Написать нам</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroSection;
