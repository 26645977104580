import { observer } from "mobx-react";
import "./InfoSection.scss";
import store from "../../../../store/store";

const InfoSection = observer(() => {
    return (
        <div className="seller-info-section">
            <div className="container">
                <div className="left"></div>
                <div className="right">
                    <div className="column">
                        <h1>Системные требования</h1>
                        <div className="icon-container">
                            <img
                                src="/images/logo-windows.svg"
                                className="logo"
                            ></img>

                            <div className="icon-text-container">
                                <p>Операционная система:</p>
                                <h2>windows7+</h2>
                            </div>
                        </div>
                        <p>
                            Программа не требовательная к ресурсам компьютера, так что
                            запустится на любом ведре 🛒
                        </p>
                    </div>

                    <div className="column">
                        <h1>А что по цене?</h1>
                        <div className="icon-container">
                            <img
                                src="/images/logo-sale.svg"
                                className="logo"
                            ></img>
                            <div className="icon-text-container">
                                <h2>{store.userAccountStore.tmsellerInfo?.price}$ в месяц</h2>
                            </div>
                        </div>
                        <p>
                            Одна подписка дает доступ к одной программе. Нет привязок по железу или чего-то подобного. Но чтобы запустить уже 2 программы одновременно нужно иметь 2 подписки
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default InfoSection;