import React, { FC } from 'react';
import { observer } from 'mobx-react';
import "./Links.scss"

const Links: FC<{}> = observer((props) => {
    return (
        <div className="help-links-section">
            <div className="container">
                <div className="links">
                    <div className="link">
                        <div className="logo-container">
                            <img className="logo" src="/images/vk.svg" />
                        </div>

                        <div className="right">
                            <div className="title">
                                вконтакте
                            </div>
                            <a
                                className="link__btn"

                            >
                                Перейти
                            </a>
                        </div>
                    </div>

                    <div className="link">
                        <div className="logo-container">
                            <img className="logo" src="/images/telegram.svg" />
                        </div>
                        <div className="right">
                            <div className="title">
                                telegram
                            </div>
                            <a
                                className="link__btn"

                            >
                                Перейти
                            </a>
                        </div>
                    </div>

                </div>

                <a className="link-gradient-long" href="/FAQ">
                    FAQ
                </a>

            </div>
        </div>
    );
});

export default Links;