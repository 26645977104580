import { observer } from "mobx-react";
import { FC, ReactNode } from "react";
import Prompt from "../Prompt/Prompt";
import ModalWindow from "../ModalWindow/ModalWindow";

const AdditionalElementsWrapper: FC<{
    children: ReactNode
}> = observer(({ children }) => {
    return <>
        <Prompt />
        <ModalWindow />
        {children}
    </>
});

export default AdditionalElementsWrapper;