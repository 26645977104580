import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import store from '../../../../store/store';
import { UserAccountPage } from '../../../../enums/storeEnums';
import { login, startRegistration } from '../../../../api/api';

const Registration = observer(() => {
    // const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [socialNetwork, setSocialNetwork] = useState("");
    const [wrongEnterEmail, setWrongEnterEmail] = useState(false);
    const [wrongEnterPassword, setWrongEnterPassword] = useState(false);
    const emailValidation = (email: string) => {
        let emailValid = email
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) === null
        setWrongEnterEmail(emailValid)
        return !emailValid
    }
    const passwordValidation = (password: string) => {
        let passwordValid = password
            .match(
                /(?=.*\d)(?=.*[a-z]).{6,}/
            ) === null
        setWrongEnterPassword(passwordValid)
        return !passwordValid
    }

    return (
        <>
            <div className='login-form'>
                <div className='login-form__wrapper'>
                    <h3 className='login-form__title'>Регистрация в системе Pinkest</h3>
                    <div className='login-form__input-container'>
                        <span className='login-form__input-title'>Email:</span>
                        <input className={!wrongEnterEmail ? 'basic-input' : "wrong-input"} name='email-reg'
                            autoComplete='off' placeholder='Начните вводить...' value={email} onChange={(e) => {
                                setEmail(e.target.value);
                                if (wrongEnterEmail)
                                    setWrongEnterEmail(false)
                            }} />
                    </div>
                    <div className='login-form__input-container'>
                        <span className='login-form__input-title'>Пароль:</span>
                        <input className={!wrongEnterPassword ? 'basic-input' : "wrong-input"}
                            placeholder='Начните вводить...'
                            type='password'
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                if (wrongEnterPassword)
                                    setWrongEnterPassword(false)
                            }} />
                    </div>
                    <div className='login-form__input-container' style={{ marginBottom: '30px' }}>
                        <span className='login-form__input-title'>Ссылка на соц. сеть:</span>
                        <input className='basic-input' placeholder='Начните вводить...' value={socialNetwork}
                            onChange={(e) => {
                                setSocialNetwork(e.target.value);
                            }} />
                    </div>

                    <button className='basic-button basic-button--gradient' onClick={() => {
                        if (emailValidation(email)) {
                            if (passwordValidation(password)) {
                                startRegistration(email, password, socialNetwork, (errorText) => {
                                    if (!errorText) {
                                        store.pageStore.openModalWindow(`Аккаунт PINKEST успешно зарегистрирован! 🤩🤗🤗
                                            Вы можете войти в личный кабинет
                                        `);
                                    } else {
                                        store.pageStore.openPrompt("error", "Ошибка регистрации", errorText!, "Хорошо")
                                    }
                                })
                            } else {
                                alert("Пароль должен содержать не меньше 6 символов и 1 букву");
                            }
                        } else {
                            alert("Неверный email");
                        }
                    }}>Регистрация
                    </button>
                    <div className='login-form__no-account' onClick={() => {
                        store.userAccountStore.setCurrentAccountPage(UserAccountPage.LOGIN);
                    }}>Есть аккаунт?
                    </div>
                </div>
            </div>
        </>
    );
});

export default Registration;