import React, {FC} from 'react';
import {observer} from 'mobx-react';
import "./Prompt.scss";
import store from '../../store/store';

const Prompt: FC<{}> = observer((props) => {
    if (!store.pageStore.promptContent) return <></>;
    return (
        <div className='prompt' onClick={(e) => {
            if ((e.target as HTMLElement).classList.contains('prompt')) {
                store.pageStore.closePrompt();
            }
        }}>
            <div className='prompt__container'>
                <img src={`/images/${store.pageStore.promptContent.type == 'error' ? "error.svg" : "success.svg"}`}
                     alt=""/>
                <h4>{store.pageStore.promptContent.title}</h4>
                <p className={store.pageStore.promptContent.text === "" ? "unVisible" : ""}>{store.pageStore.promptContent.text}</p>
                <button className='basic-button basic-button--gradient' onClick={() => {
                    store.pageStore.closePrompt();
                }}>{store.pageStore.promptContent.buttonText}</button>
            </div>
        </div>
    );
});

export default Prompt;