import React from "react";
import "./AdvantageSection.scss";

function AdvantagesSection() {
  return (
    <div className="trademanager-advantages-section">
      <div className="container">
        <h1>Почему именно TRADE MANAGER</h1>
        <div className="list">
          <div className="item">
            <img
              src="./images/trademanager-advantages-big-1.png"
              className="background"
            ></img>
            <div className="text-wrapper">
              <h2>Тотальная анонимность, поддержка прокси</h2>
              <p>
                Steam никогда не догадается, что вы один и тот же человек.
                Теперь для этого не нужно устанавливать десятки программ и
                расширений, все находится в одном месте. Цепочка красных
                табличек – это больше не ваша забота
              </p>
            </div>
          </div>
          <div className="item">
            <img
              src="./images/trademanager-advantages-big-2.png"
              className="background"
            ></img>
            <div className="text-wrapper">
              <h2>Полный функционал для работы со Steam Guard</h2>
              <p>
                Получение кодов доступа, подтверждение выставления скинов на продажу, подтверждение обменов, подключение мобильного аутентификатора и многое другое. Одним словом – все как в официальном мобильном приложении Steam, только у вас на компьютере. (есть поддержка maFile)
              </p>
            </div>
          </div>
        </div>
        <div className="list">
          <div className="item">
            <img
              src="./images/trademanager-advantages-big-3.png"
              className="background"
            ></img>
            <div className="text-wrapper">
              <h2>Встроенный браузер для любых нужд</h2>
              <p>
                Совершенно чистый, не привязанный ни к чему Google Chrome. Одна кнопка – и окошко с нужным Steam аккаунтом (а может быть с чем-то по-интереснее) у вас на экране. Браузер кстати тоже привязывается к прокси 😉
              </p>
            </div>
          </div>
          <div className="item">
            <img
              src="./images/trademanager-advantages-big-4.png"
              className="background"
            ></img>
            <div className="text-wrapper">
              <h2>Безопасность</h2>
              <p>
                Все данные шифруются. Даже если злоумышленники получат доступ к вашему компьютеру, то, будьте уверены, логины и пароли не пострадают. И к нам на сервер кстати никакая ваша информация не уходит, хранение осуществляется исключительно локально.
              </p>
            </div>
          </div>
        </div>

        <div className="list-medium">
          <div className="item">
            <img
              src="./images/trademanager-advantages-medium-1.png"
              className="background"
            ></img>
            <div className="text-wrapper">
              <h2>Тотальная анонимность, поддержка прокси</h2>
              <p>
                Steam никогда не догадается, что вы один и тот же человек.
                Теперь для этого не нужно устанавливать десятки программ и
                расширений, все находится в одном месте. Цепочка красных
                табличек – это больше не ваша забота
              </p>
            </div>
          </div>
          <div className="item">
            <img
              src="./images/trademanager-advantages-medium-2.png"
              className="background"
            ></img>
            <div className="text-wrapper">
              <h2>Полный функционал для работы со Steam Guard</h2>
              <p>
                Получение кодов доступа, подтверждение выставления скинов на продажу, подтверждение обменов, подключение мобильного аутентификатора и многое другое. Одним словом – все как в официальном мобильном приложении Steam, только у вас на компьютере. (есть поддержка maFile)
              </p>
            </div>
          </div>
        </div>
        <div className="list-medium">
          <div className="item">
            <img
              src="./images/trademanager-advantages-medium-1.png"
              className="background"
            ></img>
            <div className="text-wrapper">
              <h2>Встроенный браузер для любых нужд</h2>
              <p>
                Совершенно чистый, не привязанный ни к чему Google Chrome. Одна кнопка – и окошко с нужным Steam аккаунтом (а может быть с чем-то по-интереснее) у вас на экране. Браузер кстати тоже привязывается к прокси 😉
              </p>
            </div>
          </div>
          <div className="item">
            <img
              src="./images/trademanager-advantages-medium-2.png"
              className="background"
            ></img>
            <div className="text-wrapper">
              <h2>Безопасность</h2>
              <p>
                Все данные шифруются. Даже если злоумышленники получат доступ к вашему компьютеру, то, будьте уверены, логины и пароли не пострадают. И к нам на сервер кстати никакая ваша информация не уходит, хранение осуществляется исключительно локально.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvantagesSection;