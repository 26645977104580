import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import "./AddBalancePage.scss";
import TopPanel from '../ControlPanelPage/components/TopPanel/TopPanel';
import store from '../../../../store/store';
import { UserAccountPage } from '../../../../enums/storeEnums';
import { cryptoCloudDeposit, host, userAccountHost, yoomoneyBuildForm, yoomoneyCreateOrder } from '../../../../api/api';
import openInNewTab from '../../../../scripts/openInNewTab';
import PriceTag from '../../../../globalComponents/PriceTag/PriceTag';

const AddBalancePage: FC<{}> = observer((props) => {
    const [addBalance, setAddBalance] = useState('30');
    const [method, setMethod] = useState("USDT(TRC20)");

    return (
        <div className='container'>
            <div className='add-balance'>
                <TopPanel isAddBalancePage={true} />
                <h2 className='add-balance__title'>Пополнить баланс</h2>
                <div className='add-balance__content'>
                    <div className='add-balance__methods'>
                        {
                            (store.userAccountStore.depositMethods || []).map((el) => {
                                return (<div className={`add-balance__method ${method == el.id ? "add-balance__method--active" : ""}`} onClick={() => {
                                    setMethod(el.id);
                                }}>
                                    <img src={`/images/deposit-methods/${el.id.toLowerCase()}.svg`} className='add-balance__method-img'></img>
                                    <h3 className='add-balance__method-title'>{el.name}</h3>
                                    <p className='add-balance__method-text'>
                                        {el.description}
                                    </p>
                                </div>
                                )
                            })
                        }
                    </div>
                    <div className='add-balance__form'>
                        <input className='basic-input' placeholder='Сумма пополнения' type='number' min='0'
                            step='1' value={addBalance} onChange={(e) => {
                                if (e.target.value === '' || Number(e.target.value) >= 0) {
                                    setAddBalance(e.target.value);
                                }
                            }}></input>
                        <div className='add-balance__to-pay-container'>
                            <PriceTag prefix='К оплате: ' usdPrice={Number(addBalance)} />
                        </div>
                        <button className='basic-button basic-button--gradient' onClick={() => {    
                            openInNewTab(`${host}/add-balance?method=${method}&&value=${addBalance}`);
                        }}>Пополнить</button>
                    </div>
                </div>
            </div>
            <img className='add-balance__back-img' src="/images/slider-left.svg" alt="" onClick={() => {
                store.userAccountStore.setCurrentAccountPage(UserAccountPage.CONTROL_PANEL);
            }} />
        </div>
    );
});

export default AddBalancePage;