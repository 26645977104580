import React from "react";
import "./IntroSection.css";
import scrollToSelector from "../../../../scripts/scrollToSelector";

const IntroSection = () => {
    return (
        <section className="intro-section">
            <img id="bobina-1" src="/images/bobina-1.png" />
            <img id="bobina-2" src="/images/bobina-2.png" />
            <div className="container">
                <div className="intro-section__content">
                    <h1>
                        Разрабатываем <br /> программное обеспечение
                    </h1>
                    <div className="intro-section__desc">
                        Для торговли игровыми предметами Steam и не только
                    </div>
                    <a
                        className="actions__btn"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            scrollToSelector("#root > div:nth-child(2) > div > div > div.product-section__content > div.title");
                        }}
                    >
                        <span className="gradient-text">Наши продукты</span>
                    </a>
                </div>
            </div>
            <img src="/images/show-more-button.svg" className="button"></img>
        </section>
    );
}

export default IntroSection;
