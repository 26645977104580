import React from "react";
import "./AboutSection.scss";

const AboutSection = () => {
    return (
        <div className="trademanager-about-section">
            <div className="gradient"></div>
            <div className="container">
                <div className="left">
                    <h1>О приложении</h1>
                    <p>
                        Это ультимативная вещь для трейда. Больше не нужны никакие программы для настройки прокси, бесконечных файликов с данными, excel таблиц. Все в одном приложении, удобно и понятно.<br />
                        Вся информация, которая хранится в менеджере шифруется и хранится исключительно локально на вашем компьютере. Ни у кого не будет доступа к логинам и паролям от ваших аккаунтов, даже у нас.<br />
                        На данный момент приложение находится в состоянии тестирования и доступно всем желающим. Чтобы её скачать необходимо зарегистрироваться по ссылке сверху. Вам будет предоставлена подробная инструкция с описанием каждого элемента программы и непосредственно ссылка на скачивание.<br />

                    </p>
                </div>
                <div className="right"></div>
            </div>
        </div>
    );
}

export default AboutSection;
