import React from "react";
import "./Crypto.scss";

function Crypto() {
  return (
    <div className="custom-soft-crypto">
      <div className="container">
        <div className="left"></div>
        <div className="right">
          <h1>
            А для <span className="gradient-text">крипты</span> делаете софты?
          </h1>
          <p>Это конечно не наша прямая специализация, но тем не менее если вы сможете доходчиво донести до разработчиков идею, то дело в шляпе.</p>
        </div>
      </div>
      <div className="gradient"></div>

    </div>
  );
}

export default Crypto;
