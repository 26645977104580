import React, { FC } from 'react';
import { observer } from 'mobx-react';
import PageWrapper from '../../globalComponents/PageWrapper/PageWrapper';
import "./Unknown.scss";

const Unknown: FC<{}> = observer((props) => {
    return (
        <PageWrapper>

            <div className='unknown-page'>
                <div className='container'>
                    <img src="/images/unknown.svg" alt="" />
                    <h1>УПС. Неизвестная страница</h1>
                </div>
            </div>
        </PageWrapper>
    );
});

export default Unknown;