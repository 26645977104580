import React from "react";
import "./InfoSection.scss";

const InfoSection = () => {
    return (
        <div className="trademanager-info-section">
            <div className="container">
                <div className="left"></div>
                <div className="right">
                    <div className="column">
                        <h1>Системные требования</h1>
                        <div className="icon-container">
                            <img
                                src="./images/logo-windows.svg"
                                className="logo"
                            ></img>

                            <div className="icon-text-container">
                                <p>Операционная система:</p>
                                <h2>windows7+</h2>
                            </div>
                        </div>
                        <p>
                            Программа не требовательная к ресурсам компьютера, так что
                            запустится на любом ведре 🤑
                        </p>
                    </div>

                    <div className="column">
                        <h1>А что по цене?</h1>
                        <div className="icon-container">
                            <img
                                src="./images/logo-sale.svg"
                                className="logo"
                            ></img>
                            <div className="icon-text-container">
                                <h2>Бесплатно</h2>
                            </div>
                        </div>
                        <p>
                            да вы не оcлышались, это все совершенно бесплатно пока идет
                            тестовый период. Так что успевайте запрыгнуть в последний вагон 😎
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoSection;