import React, { FC } from 'react';
import { observer } from 'mobx-react';
import store from '../../../../../../../store/store';
import { TMSellerTab, TrademanagerTab } from '../../../../../../../enums/storeEnums';
import LinkScreen from '../LinkScreen/LinkScreen';
import { host } from '../../../../../../../api/api';

const TrademanagerContent: FC = observer(() => {
    switch (store.userAccountStore.trademanagerContent.selectedTab) {
        case TrademanagerTab.DOWNLOAD:
            return <LinkScreen title='Нажмите накнопку и дождитесь окончания загрузки' img='/images/trademanager/logo.png' buttonText='Скачать' link={`${host}/trademanager/download-app`} />;
        case TrademanagerTab.DESCRIPTION:
            return <LinkScreen title='Описание функционала trademanager на сайте' img='/images/trademanager/logo.png' buttonText='Открыть' link={`${host}/trademanager`} />;
        case TrademanagerTab.INSTRUCTION:
            return <LinkScreen title={'Инструкция по настройке и работе с Trademanager в notion'} buttonText={'Открыть'} img={'/images/tmseller/notion.svg'} link='https://factual-sail-6ac.notion.site/Trademanager-6dc739f10d11407690b77532b9d068c5' />;
        default:
            return <>Такой вкладки нет!</>;
    }
});

export default TrademanagerContent;