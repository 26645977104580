import React from "react";
import "./SafetySection.scss";

const SafetySection = () => {
    return (
        <div className="seller-safety-section">
            <div className="container">
                <div className="left"></div>
                <div className="right">
                    <h1>Можно вернуть средства</h1>
                    <p>
                        Мы гарантируем качество продукта. Поэтому если вас что-то не устроит в первые 3 дня после покупки, то всегда можно обратиться в поддержку и вернуть потраченные деньги
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SafetySection;