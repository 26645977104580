import "./TopPanel.scss";
import store from "../../../../../../store/store";
import { observer } from "mobx-react";
import { logout } from "../../../../../../api/api";
import { UserAccountPage } from "../../../../../../enums/storeEnums";
import { FC } from "react";
import PriceTag from "../../../../../../globalComponents/PriceTag/PriceTag";
import openInNewTab from "../../../../../../scripts/openInNewTab";

const TopPanel: FC<{ isAddBalancePage?: boolean }> = observer((props) => {
    return <div className='top-panel'>
        <div className='top-panel__left'>
            <div className='top-panel__balance'>
                <div className='top-panel__balance-block'>
                    <PriceTag usdPrice={store.userAccountStore.user?.balance} prefix="Баланс: " />
                </div>
                {
                    props.isAddBalancePage === true ?
                        <div className='top-panel__balance-button' onClick={() => {
                            store.userAccountStore.setCurrentAccountPage(UserAccountPage.CONTROL_PANEL)
                        }}>← Вернуться
                        </div> :
                        <div className='top-panel__balance-button' onClick={() => {
                            store.userAccountStore.setCurrentAccountPage(UserAccountPage.ADD_BALANCE)
                        }}>Пополнить
                        </div>
                }
            </div>
            <div className='top-panel__user-info'>
                <div className='top-panel__balance-block'>
                    email:
                    <div className='top-panel__email'>{store.userAccountStore.user?.email}</div>
                </div>
                <a className='top-panel__logout-btn' href="/user-account" onClick={() => {
                    logout(() => {

                    });
                }}>
                    <img src='./images/exit_to_app.svg'></img>
                </a>
            </div>
        </div>
        <div className='top-panel__right' onClick={() => {
            if (store.userAccountStore.generalInfo)
                openInNewTab(store.userAccountStore.generalInfo.chatLink);
        }}>
            <div className='top-panel__chat-button'>
                <img src='./images/Vk-gradient.svg'></img>
                <div>Онлайн-чат</div>
            </div>
        </div>
    </div>;
});

export default TopPanel;