import React, {FC} from 'react';
import {observer} from 'mobx-react';
import store from '../../store/store';
import "./ModalWindow.scss";

const ModalWindow: FC<{}> = observer((props) => {
    if (!store.pageStore.modalWindowContent) return <></>;


    return (
        <div className='modal-window' onClick={(e) => {
            if ((e.target as HTMLElement).classList.contains("modal-window")) {
                store.pageStore.closeModalWindow();
            }
        }}>
            <div className='modal-window__container' id="m">
                {
                    store.pageStore.modalWindowContent.text
                }
            </div>
        </div>
    );


});

export default ModalWindow;