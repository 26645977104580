import React, { FC } from 'react';
import { observer } from 'mobx-react';
import Item from './Item';
import "./FAQ.scss";
import PageWrapper from '../../../../globalComponents/PageWrapper/PageWrapper';

const FAQ: FC<{}> = observer((props) => {
    return (
            <div className='help-faq'>
                <div className='container'>
                    <h1>Ответы на частые вопросы</h1>
                    <div className='list'>
                        <Item question="Кто вы такие?" answer="Мы трейдеры в Steam, которые решили помогать другим людям с софтом для торговли! Мы уже несколько лет работаем в Steam и знаем практически все его лазейки и особенности"></Item>
                        <Item question="Как с вами связаться?" answer="Ссылочки выше 👆"></Item>
                        <Item question="За ваши софты дают КТ?" answer="Если бы давали, то мы бы их не продавали. А вообще красную табличку можно получить и используя нашу продукцию, если не знать определенных правил"></Item>
                        <Item question="Хочу с вами работать!" answer="По всем предложениям можно написать в vk или в telegram 👆. Мы обязательно ответим на любое сообщение"></Item>
                    </div>
                </div>
            </div>
    );
});

export default FAQ;