import React from "react";
import "./AboutUs.scss";

function AboutUs() {
  return (
    <div className="custom-sofr-about-us">
      <div className="container">
        <div className="left">
          <div className="item">
            <h1>
              Давно хотели сайт для продажи ключей, скинов, а может быть…
              сделать свою собственную таблицу?
            </h1>
          </div>
          <div className="item">
            <h1>
              Мы с радостью возьмемся за создание и чего-то более грандиозного.
            </h1>
          </div>
        </div>
        <div className="right">
          <div className="item">
            <h1>
              В общем, все что связано со словами <span className='h1_gradient'>АВТОМАТИЗАЦИЯ</span> и <span className='h1_gradient'>STEAM</span> - это к
              нам.
            </h1>
            <img src="/images/logo.svg" className="logo"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
