import {observer} from 'mobx-react';
import React, {FC, useState} from 'react';
import {UserAccountPage} from '../../../../enums/storeEnums';
import store from '../../../../store/store';
import './LoginPage.scss';
import {getBalance, login} from '../../../../api/api';

const LoginPage = observer(() => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [wrongEnterEmail, setWrongEnterEmail] = useState(false);
    const [wrongEnterPassword, setWrongEnterPassword] = useState(false);
    const emailValidation = (email: string) => {
        let emailValid = email
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) === null
        setWrongEnterEmail(emailValid)
        return !emailValid
    }
    const passwordValidation = (password: string) => {
        let passwordValid = password
            .match(
                /(?=.*\d)(?=.*[a-z]).{6,}/
            ) === null
        setWrongEnterPassword(passwordValid)
        return !passwordValid
    }

    return (
        <>
            <div className='login-form'>
                <div className='container'>
                    <h3 className='login-form__title'>Авторизация в системе Pinkest</h3>
                    <div className='login-form__input-container'>
                        <span className='login-form__input-title'>Email:</span>
                        <input className={!wrongEnterEmail ? 'basic-input' : "wrong-input"}
                               placeholder='Начните вводить...'
                               value={email} onChange={(e) => {
                            setEmail(e.target.value);
                            if (wrongEnterEmail)
                                setWrongEnterEmail(false)
                        }}/>
                    </div>
                    <div className='login-form__input-container'>
                        <span className='login-form__input-title'>Пароль:</span>
                        <input className={!wrongEnterPassword ? 'basic-input' : "wrong-input"}
                               placeholder='Начните вводить...'
                               type='password'
                               value={password}
                               onChange={(e) => {
                                   setPassword(e.target.value);
                                   if (wrongEnterPassword)
                                       setWrongEnterPassword(false)
                               }}/>
                    </div>
                    <div className='login-form__forgot-password' onClick={() => {
                        store.userAccountStore.setCurrentAccountPage(UserAccountPage.RESTORE_ACCESS);
                    }}>Забыли пароль?
                    </div>
                    <button className='basic-button basic-button--gradient' onClick={() => {
                        if (emailValidation(email)) {
                            if (passwordValidation(password)) {
                                login(email, password, (errorText) => {
                                    if (!errorText) {
                                        window.location.replace("/user-account");
                                    } else {
                                        alert(errorText);
                                    }
                                });
                            } else {
                                alert("Пароль должен содержать не меньше 6 символов и 1 букву");
                            }
                        } else {
                            alert("Неверный email");
                        }
                    }}>Войти
                    </button>
                    <div className='login-form__no-account' onClick={() => {
                        store.userAccountStore.setCurrentAccountPage(UserAccountPage.REGISTRATION);
                    }}>Нет аккаунта?
                    </div>
                </div>
            </div>
        </>
    );
});

export default LoginPage;