import React, { useRef, FC } from "react";
import AliceCarousel from "react-alice-carousel";
import "./GallerySection.scss";

const GallerySection: FC<{ images: string[], title: string }> = ({ images, title }) => {
    const items = images.map((image) => {
        return (
            <div className="wrapper">
                <img src={image} className="picture"></img>
            </div>
        );
    });

    const responsive = {};

    const carousel = useRef(null);

    //@ts-ignore
    return (
        <div className="gallery">
            <div className="container">
                <div className="navigation-section">
                    <div className="text">{title}</div>
                    <div className="sliders" key="btns">

                        <div className="btn-left" onClick={(e) =>
                            //@ts-ignore
                            carousel.current.slidePrev(e)
                        }></div>
                        <div className="btn-right" onClick={(e) =>
                            //@ts-ignore
                            carousel.current.slideNext(e)
                        }></div>
                    </div>
                </div>
                {//@ts-ignore
                    <AliceCarousel infinite={true} items={items} responsive={responsive} disableButtonsControls ref={carousel} />
                }
            </div>
        </div>
    );
}

export default GallerySection;
