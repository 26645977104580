import React from "react";
import "./IntroSection.scss";
import store from "../../../../store/store";
import { TrademanagerTab } from "../../../../enums/storeEnums";

const IntroSection = () => {
    return (
        <div className="trademanager-intro-section">
            <div className="container">
                <div className="left">
                    <div className="image"></div>
                    <div className="price">
                        <h1>Бесплатно</h1>
                        <p></p>
                        <a className="buy-btn" href="/user-account?app=trademanger&tab=download">
                            <span>Скачать программу</span>
                        </a>
                    </div>
                </div>
                <div className="right">
                    <h1>Trade manager</h1>
                    <p>
                        Программа для удобной работы с большим количеством аккаунтов Steam. Замена sda, мобильного аутентификатора Steam, браузера, бесконечных excel таблиц и текстовых файликов и многих других вещей
                    </p>
                    <div className="list">
                        <div className="item">
                            <img src="/images/basketball.svg" className="icon"></img>
                            <h3>Отдельное окно браузера для каждого аккаунта</h3>
                        </div>
                        <div className="item">
                            <img src="/images/key.svg" className="icon"></img>
                            <h3>Встроенное sda (доступны все функции guard без использования смартфона)</h3>
                        </div>
                        <div className="item">
                            <img src="/images/shield.svg" className="icon"></img>
                            <h3>Смена ip-адреса с помощью прокси</h3>
                        </div>
                    </div>
                    <div className="price">
                        <h1>Бесплатно</h1>
                        <p></p>
                        <a
                            className="buy-btn"
                            href="/user-account?app=trademanger&tab=download"
                        >
                            <span>Скачать программу</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IntroSection;
