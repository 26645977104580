import React from "react";
import "./LearnMoreSection.scss";

function LearnMoreSection() {
  return (
    <div className="learn-more-section">
      <div className="logo-big"></div>
      <div className="container">
        <div className="left"></div>

        <div className="right">
          <div className="content">
            <h1>TM SELLER уже доступен</h1>
            <span>
              - бот для быстрой продажи предметов CSGO на сайте market.csgo.com
            </span>
            <a href="/tmseller" className="actions__link-btn">
              <span>Узнать больше</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnMoreSection;
