import React from "react";
import "./IntroSection.scss";
import { observer } from "mobx-react";
import store from "../../../../store/store";
import PriceTag from "../../../../globalComponents/PriceTag/PriceTag";
import { TMSellerTab } from "../../../../enums/storeEnums";

const IntroSection = observer(() => {
    return (
        <div className="seller-intro-section">
            <div className="container">
                <div className="left">
                    <h1>TM Seller</h1>
                    <p>
                        Часто продаете предметы на market.csgo.com? Тогда этот продукт для вас! 😘 TM Seller - это
                        пожалуй лучшее программное обеспечение для быстрой и самое главное удобной продажи предметов
                        Steam за реальные деньги на TM
                    </p>
                    <div className="list">
                        <div className="item">
                            <img src="/images/rocket.svg" className="icon"></img>
                            <h1>Все автоматизировано</h1>
                        </div>
                        <div className="item">
                            <img src="/images/analize.svg" className="icon"></img>
                            <h1>Анализ цен с площадок</h1>
                        </div>
                        <div className="item">
                            <img src="/images/receipt.svg" className="icon"></img>
                            <h1>Перебитие конкурентов</h1>
                        </div>
                    </div>
                    <div className="price">
                        <PriceTag usdPrice={store.userAccountStore.tmsellerInfo?.price} />
                        <p></p>
                        <a onClick={() => {
                            store.userAccountStore.setTMSellerContent({
                                selectedTab: TMSellerTab.MY_BOTS
                            });
                        }}
                            className="buy-btn"
                            href="/user-account"
                        >
                            <span><a href="/user-account?app=tmseller&tab=instruction">Начать пользоваться</a></span>
                        </a>
                    </div>
                </div>
                <div className="right">
                    <div className="image"></div>
                    <div className="price">
                        <PriceTag usdPrice={store.userAccountStore.tmsellerInfo?.price} />
                        <p></p>
                        <a
                            onClick={() => {

                            }}
                            className="buy-btn"
                        >
                            <span><a href="/user-account?app=tmseller&tab=instruction">Начать пользоваться</a></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default IntroSection;
