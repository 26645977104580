import React from "react";
import "./ProductSection.scss";
import store from "../../../../store/store";
import { observer } from "mobx-react";
import PriceTag from "../../../../globalComponents/PriceTag/PriceTag";

const ProductSection = observer(() => {
    return (
        <div className="product-section">
            <div className="container">
                <div className="gradient"></div>
                <div className="product-section__content">
                    <div className="title">Наши продукты</div>

                    <div className="product-section__slider">
                        <div className="product">
                            <div className="product-top">
                                <img
                                    src="/images/product-1-logo.png"
                                    className="product-logo"
                                ></img>
                                <div className="product-top__info">
                                    <h1>Trade manager</h1>
                                    <span>
                                        Все для трейда в одном месте. Браузер, все функции Steam,
                                        работа с прокси. Наглядно и удобно
                                    </span>
                                </div>
                            </div>
                            <div className="product-bottom">
                                <div className="price-container">
                                    <h1>Бесплатно</h1>
                                    <span></span>
                                </div>
                                <a href={`/trademanager`} className="product-explore__btn">
                                    <span>Подробнее</span>
                                </a>
                            </div>
                        </div>
                        <div className="product">
                            <div className="product-top">
                                <img
                                    src="/images/product-2-logo.png"
                                    className="product-logo"
                                ></img>
                                <div className="product-top__info">
                                    <h1>TM Seller</h1>
                                    <span>
                                        Автоматизированная продажа предметов на сайте
                                        Market.csgo.com
                                    </span>
                                </div>
                            </div>
                            <div className="product-bottom">
                                <div className="price-container">
                                    <PriceTag usdPrice={store.userAccountStore.tmsellerInfo?.price} />
                                </div>
                                <a href={`TMSeller/`} className="product-explore__btn">
                                    <span>Подробнее</span>
                                </a>
                            </div>
                        </div>
                        {/* <div className="product">
              <div className="product-top">
                <img
                  src="/images/product-3-logo.png"
                  className="product-logo"
                ></img>
                <div className="product-top__info">
                  <h1>Аккаунты Steam</h1>
                  <span>
                    Нужны аккаунты-пустышки для трейда? Вы по адресу! У нас
                    подготовлены сотни таких. Есть даже с открытой торговой
                    площадкой 😎
                  </span>
                </div>
              </div>
              <div className="product-bottom">
                <div className="price-container">
                  <h1>от 100 руб</h1>
                </div>
                <a href="SteamAccounts/" className="product-explore__btn">
                  <span>Подробнее</span>
                </a>
              </div>
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ProductSection;
