import React, { FC } from 'react';
import { observer } from 'mobx-react';
import store from '../../store/store';
import "./PriceTag.scss";

const PriceTag: FC<{ usdPrice?: number, postfix?: string, prefix?: string }> = observer(({
    usdPrice, postfix, prefix
}) => {
    if (!usdPrice && usdPrice != 0) {
        return <>не определено</>
    }
    return (
        <div className="price-tag">
            <span className='price-tag__prefix'>
                {prefix ? prefix : ""}
            </span>
            <div className="price-tag__usd">
                {usdPrice.toFixed(2)} $
            </div>
            <div className="price-tag__rub">
                ({(usdPrice * store.userAccountStore.usdRate!).toFixed(2)} руб.)
            </div>
            <span className="price-tag__postfix">{postfix ? postfix : ""}</span>
        </div>
    );
});

export default PriceTag;