import React, { FC } from 'react';
import { observer } from 'mobx-react';
import PageWrapper from '../../globalComponents/PageWrapper/PageWrapper';
import IntroSection from './Sections/IntroSection/IntroSection';
import GallerySection from '../../globalComponents/GallerySection/GallerySection';
import GuideSection from '../../globalComponents/GuideSection/GuideSection';
import FAQSection from '../../globalComponents/FAQSection/FAQSection';
import AboutSection from './Sections/AboutSection/AboutSection';
import AdvantageSection from './Sections/AdvantageSection/AdvantageSection';
import AppSection from './Sections/AppSection/AppSection';
import InfoSection from './Sections/InfoSection/InfoSection';

const Trademanager: FC<{}> = observer((props) => {
    return (
        <>
            <PageWrapper>
                <IntroSection />
                <GallerySection title="Скрины приложения" images={["./images/trademanager/screen1.png", "./images/trademanager/screen2.png", "./images/trademanager/screen3.jpg"]} />
                <AboutSection />
                <AdvantageSection />
                <AppSection />
                <GuideSection links={[
                    {
                        link: "https://google.com",
                        text: "Видео гайд по использованию",
                        backgroundImage: "/images/trademanager/screen1.png"
                    },
                    {
                        link: "https://google.com",
                        text: "Текстовый гайд по использованию",
                        backgroundImage: "/images/trademanager/screen3.png",
                        icon: "/images/guideblock-right.svg"
                    },
                    {
                        link: "https://google.com",
                        text: "Техническая документация (для кодеров и прошаренных ребят)",
                        backgroundImage: "/images/background-text-guide.jpg",
                        icon: "/images/guideblock-right.svg"
                    }
                ]} />
                <InfoSection />
                <FAQSection />
            </PageWrapper>
        </>
    );
});

export default Trademanager;