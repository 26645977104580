import React from "react";
import "./OurMission.scss";

function OurMission() {
  return (
    <div className="our-mission">
      <div className="container">
        <h1>
          <span className="gradient-text">Наша основная задача</span> - сделать
          все качественно и получить от вас максимальную обратную связь
        </h1>
      </div>
    </div>
  );
}

export default OurMission;
