import { useEffect, useRef, useState } from "react";

import Steam from "./Tabs/Steam/Steam";
import Team from "./Tabs/Team/Team";
import AboutUs from "./Tabs/AboutUs/AboutUs";
import CreatedProject from "./Tabs/CreatedProject/CreatedProject";
import OurMission from "./Tabs/OurMission/OurMission";
import ContactWithUs from "./Tabs/ContactWithUs/ContactWithUs";
import Crypto from "./Tabs/Crypto/Crypto";
import IntroSection from "./Tabs/IntroSection/IntroSection";
import PageWrapper from "../../globalComponents/PageWrapper/PageWrapper";
import Links from "../Help/Sections/Links/Links";

export default function CustomSoft() {

  return (
    <PageWrapper>
      <IntroSection></IntroSection>
      <Steam></Steam>
      <AboutUs></AboutUs>
      <Team></Team>
      <CreatedProject></CreatedProject>
      <OurMission></OurMission>
      <ContactWithUs></ContactWithUs>
      <Crypto></Crypto>
      <Links></Links>
    </PageWrapper>
  );
}
