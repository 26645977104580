import React, { FC } from 'react';
import { observer } from 'mobx-react';
import PageWrapper from '../../globalComponents/PageWrapper/PageWrapper';
import Links from './Sections/Links/Links';
import FAQ from './Sections/FAQ/FAQ';
import "./Help.scss";

const Help: FC<{}> = observer((props) => {
    return (
        <PageWrapper>
            <div className="help">
                <h1>Помощь</h1>
                <h2>Задайте вопрос и следите за новостями</h2>
                <p>Мы всегда готовы ответить на ваши вопросы</p>
                <Links></Links>
                <FAQ></FAQ>
            </div>
        </PageWrapper>
    );
});

export default Help;