import { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "./FunctionalSection.scss";  

const FunctionalSection = () => {
    const items = [
        <div className="slider__slide" data-value="1">
            <div
                className="slider_slide-content"
                style={{
                    background: "no-repeat url('/images/functional-background.png')",
                    backgroundSize: "contain",
                }}
            >
                <img src="/images/exclude.svg" className="image"></img>
                <h1>Передача предметов покупателю</h1>
                <span>
                    Как только у вас купят предмет, бот сразу же отправит обмен. Не нужно использовать какие-то дополнительные программы
                </span>
            </div>
        </div>,
        <div className="slider__slide" data-value="2">
            <div
                className="slider_slide-content"
                style={{
                    background: "no-repeat url('/images/functional-background.png')",
                    backgroundSize: "contain",
                }}
            >
                <img src="/images/functional-logo2.svg" className="image"></img>

                <h1>Быстрое выставление скинов на продажу</h1>
                <span>
                    Бот сам выставит выбранные вами предметы на продажу по указанным параметра
                </span>
            </div>
        </div>,
        <div className="slider__slide" data-value="3">
            <div
                className="slider_slide-content"
                style={{
                    background: "no-repeat url('/images/functional-background.png')",
                    backgroundSize: "contain",
                }}
            >
                <img src="/images/functional-logo3.svg" className="image"></img>
                <h1>Перебитие конкурентов</h1>
                <span>
                    Перебитие работает по всем лотам! Бот поставит актуальную цену на скин, учитывая максимальный и минимальный лимит<br /><br /><br />
                </span>
            </div>
        </div>,
        <div className="slider__slide" data-value="4">
            <div
                className="slider_slide-content"
                style={{
                    background: "no-repeat url('/images/functional-background.png')",
                    backgroundSize: "contain",
                }}
            >
                <img src="/images/functional-logo4.svg" className="image"></img>
                <h1>Ваши данные под надежной защитой</h1>
                <span>
                    Ничего, абсолютно ничего не уходит на сервер. Все ваши данные хранятся исключительно локально<br /><br />
                </span>
            </div>
        </div>,
        <div className="slider__slide" data-value="4">
            <div
                className="slider_slide-content"
                style={{
                    background: "no-repeat url('/images/functional-background.png')",
                    backgroundSize: "contain",
                }}
            >
                <img src="/images/functional-logo4.svg" className="image"></img>
                <h1>Сделка не удалась? Ничего страшного!</h1>
                <span>
                    Даже если так, то бот снова выставит скин на продажу, вам не придется даже вставать с кровати <br /><br />
                </span>
            </div>
        </div>,
        <div className="slider__slide" data-value="4">
            <div
                className="slider_slide-content"
                style={{
                    background: "no-repeat url('/images/functional-background.png')",
                    backgroundSize: "contain",
                }}
            >
                <img src="/images/functional-logo4.svg" className="image"></img>
                <h1>Удобный и понятный интерфейс</h1>
                <span>
                    Да, да, да, интерфейс без лагов, затупов и прочих неприятностей<br /><br /><br />
                </span>
            </div>
        </div>

    ];

    const responsive = {
        400: { items: 1 },
        700: { items: 2 },
        1060: { items: 3 },
        1240: { items: 4 },
    };

    const carousel = useRef(null);

    return (
        <section className="functional-section">
            <div className="container">
                <div className="gradient"></div>
                <div className="advantages-section__info">
                    <div className="text">Функционал приложения</div>
                    <div className="sliders" key="btns">
                        <div
                            className="btn-left"
                            //@ts-ignore
                            onClick={(e) => carousel?.current?.slidePrev(e)}
                        ></div>
                        <div
                            className="btn-right"
                            //@ts-ignore
                            onClick={(e) => carousel?.current?.slideNext(e)}
                        ></div>
                    </div>
                </div>

                <div className="advantages-section__slider">

                    {//@ts-ignore
                        <AliceCarousel
                            infinite={true}
                            items={items}
                            responsive={responsive}
                            disableButtonsControls
                            ref={carousel}
                        />}
                </div>
            </div>
        </section>
    );
}

export default FunctionalSection;