import React from "react";
import "./ContactWithUs.scss";

function ContactWithUs() {
  return (
    <div className="contact-with-us">
      <div className="container">
        <div className="wrapper">
          <div className="left">
            <h1>Нам не принципиально где вы будете излагать свою идею</h1>
            <p>
              Можем созвониться в Discord или переписываться в любом удобном для
              вас мессенджере
            </p>
            <a
              className="work-btn"
              onClick={() => {

              }}
            >
              <span>Я хочу с вами поработать!</span>
            </a>
          </div>
          <div className="right"></div>
        </div>
      </div>
    </div>
  );
}

export default ContactWithUs;
