import React, { FC } from 'react';
import { observer } from 'mobx-react';
import store from '../../../../../../store/store';
import { TMSellerTab, TrademanagerTab } from '../../../../../../enums/storeEnums';
import TMSellerContent from './TMSellerContent/TMSellerContent';
import TrademanagerContent from './TrademanagerContent/TrademanagerContent';

const AppContent: FC<{}> = observer((props) => {
    let activeTab;

    switch (store.userAccountStore.selectedApp) {
        case "tmseller":
            activeTab = store.userAccountStore.tmsellerContent.selectedTab;

            return <div className='user-account__right-content'>
                <div className='user-account__content-buttons'>
                    <div
                        className={'user-account__content-button ' + ((activeTab === TMSellerTab.MY_BOTS) ? "user-account__content-button_active" : '')}
                        onClick={() => {
                            store.userAccountStore.setTMSellerContent({
                                selectedTab: TMSellerTab.MY_BOTS
                            });
                        }}>
                        <img src='./images/user-account/account_circle.svg'></img>
                        Мои боты
                    </div>
                    <div
                        className={'user-account__content-button ' + ((activeTab === TMSellerTab.DESCRIPTION) ? "user-account__content-button_active" : '')}
                        onClick={() => {
                            store.userAccountStore.setTMSellerContent({
                                selectedTab: TMSellerTab.DESCRIPTION
                            });
                        }}>
                        <img src='./images/user-account/account_description.svg'></img>
                        Описание
                    </div>
                    <div
                        className={'user-account__content-button ' + ((activeTab === TMSellerTab.INSTRUCTION) ? "user-account__content-button_active" : '')}
                        onClick={() => {
                            store.userAccountStore.setTMSellerContent({
                                selectedTab: TMSellerTab.INSTRUCTION
                            });
                        }}>
                        <img src='./images/user-account/account_question.svg'></img>
                        Инструкции
                    </div>
                    <div
                        className={'user-account__content-button ' + (activeTab === TMSellerTab.ADD_BOT ? "user-account__content-button_active" : '')}
                        onClick={() => {
                            store.userAccountStore.setTMSellerContent({
                                selectedTab: TMSellerTab.ADD_BOT
                            });
                        }}>
                        <img src='./images/user-account/account_plus.svg'></img>
                        Добавить
                    </div>
                    <div
                        className={'user-account__content-button ' + ((activeTab === TMSellerTab.TRANSFER_BOT) ? "user-account__content-button_active" : '')}
                        onClick={() => {
                            store.userAccountStore.setTMSellerContent({
                                selectedTab: TMSellerTab.TRANSFER_BOT
                            });
                        }}>
                        <img src='./images/user-account/account_swap.svg'></img>
                        Перенести
                    </div>
                    <div
                        className={'user-account__content-button ' + ((activeTab === TMSellerTab.DOWNLOAD) ? "user-account__content-button_active" : '')}
                        onClick={() => {
                            store.userAccountStore.setTMSellerContent({
                                selectedTab: TMSellerTab.DOWNLOAD
                            });
                        }}>
                        <img src='./images/user-account/account_download.svg'></img>
                        Скачать
                    </div>
                </div>
                <TMSellerContent />
            </div>;
        case "trademanager":
            activeTab = store.userAccountStore.trademanagerContent.selectedTab;

            return <div className='user-account__right-content'>
                <div className='user-account__content-buttons'>
                    <div
                        className={'user-account__content-button ' + ((activeTab === TrademanagerTab.DESCRIPTION) ? "user-account__content-button_active" : '')}
                        onClick={() => {
                            store.userAccountStore.setTrademanagerContent({
                                selectedTab: TrademanagerTab.DESCRIPTION
                            });
                        }}>
                        <img src='./images/user-account/account_description.svg'></img>
                        Описание
                    </div>
                    <div
                        className={'user-account__content-button ' + ((activeTab === TrademanagerTab.INSTRUCTION) ? "user-account__content-button_active" : '')}
                        onClick={() => {
                            store.userAccountStore.setTrademanagerContent({
                                selectedTab: TrademanagerTab.INSTRUCTION
                            });
                        }}>
                        <img src='./images/user-account/account_question.svg'></img>
                        Инструкция
                    </div>
                    <div
                        className={'user-account__content-button ' + ((activeTab === TrademanagerTab.DOWNLOAD) ? "user-account__content-button_active" : '')}
                        onClick={() => {
                            store.userAccountStore.setTrademanagerContent({
                                selectedTab: TrademanagerTab.DOWNLOAD
                            });
                        }}>
                        <img src='./images/user-account/account_download.svg'></img>
                        Скачать
                    </div>
                </div>
                <TrademanagerContent />
            </div>;
        default:
            return <></>;
    }
});

export default AppContent;