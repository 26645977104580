import axios from "axios";
import { DepositMethodInterface, TMSellerBotInterface, TMSellerInfoInterface } from "../interfaces/storeInterfaces";

export const host = "https://pinkest.dev";
export const userAccountHost = "https://pinkest.dev";

export const downloadPanel = () => {
    axios.get(`${userAccountHost}/tmseller/download-panel`);
}

export const yoomoneyCreateOrder = (value: string, cb: (errorText: string | null, orderId?: string) => void) => {
    axios.post(`${userAccountHost}/user/yoomoney-create-order`, { accessToken: localStorage.getItem("accessToken"), value }).then(value => {
        if (value.data.success) {
            cb(null, value.data.orderId);
        } else {
            cb(value.data.errorText);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const yoomoneyBuildForm = (orderId: string, cb: (errorText: string | null) => void) => {
    axios.get(`${userAccountHost}/user/yoomoney-build-form/${orderId}`).then(value => {
        if (value.data.success) {
            cb(null);
        } else {
            cb(value.data.errorText);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const cryptoCloudDeposit = (value: string, cb: (errorText: string | null, url?: string) => void) => {
    axios.post(`${userAccountHost}/user/crypto-cloud-deposit`, { accessToken: localStorage.getItem("accessToken"), value }).then(value => {
        if (value.data.success) {
            cb(null, value.data.url);
        } else {
            cb(value.data.errorText);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const getDepositMethods = (cb: (errorText: string | null, depositMethods?: DepositMethodInterface[]) => void) => {
    axios.get(`${userAccountHost}/user/deposit-methods`).then(value => {
        if (value.data.success === false) {
            cb(value.data.errorText);
        } else {
            cb(null, value.data);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const getMyTmSellerBots = (cb: (errorText: string | null, tmsellerBots?: TMSellerBotInterface[]) => void) => {
    axios.post(`${userAccountHost}/user/tmseller/my-bots`, {
        accessToken: localStorage.getItem("accessToken")
    }).then(value => {
        if (value.data.success) {
            cb(null, value.data.bots);
        } else {
            cb(value.data.errorText);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const createTmSellerBot = (name: string, month: number, cb: (errorText: string | null) => void) => {
    axios.post(`${userAccountHost}/user/tmseller/add-new-bot`, {
        name,
        month,
        accessToken: localStorage.getItem("accessToken")
    }).then(value => {
        if (value.data.success) {
            cb(null);
        } else {
            cb(value.data.errorText);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const transferOldTmSellerBot = (name: string, password: string, cb: (errorText: string | null) => void) => {
    axios.post(`${userAccountHost}/user/tmseller/add-old-bot`, {
        name,
        password,
        accessToken: localStorage.getItem("accessToken")
    }, {
        withCredentials: true,
        //@ts-ignore
        crossDomain: true
    }).then(value => {
        if (value.data.success) {
            cb(null);
        } else {
            cb(value.data.errorText);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const getAccessToken = (cb: (errorText: string | null) => void) => {
    axios.post(`${userAccountHost}/user/access-token`, {
        refreshToken: localStorage.getItem("refreshToken")
    }).then(value => {
        if (value.data.success) {
            localStorage.setItem("accessToken", value.data.accessToken);
            cb(null);
        } else {
            cb(value.data.errorText);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const getUsdRate = (cb: (errorText: string | null, usdRate?: number) => void) => {
    axios.get(`${userAccountHost}/usd-rate`).then(value => {
        if (value.data.success) {
            cb(null, value.data.usdRate);
        } else {
            cb(value.data.errorText);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const getBalance = (cb: (errorText: string | null, data?: { email: string, balance: number }) => void) => {
    axios.post(`${userAccountHost}/user/balance`, {
        accessToken: localStorage.getItem("accessToken")
    }).then(value => {
        if (value.data.success) {
            cb(null, value.data.content);
        } else {
            cb(value.data.errorText);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const login = (email: string, password: string, cb: (errorText: string | null) => void) => {
    axios.post(`${userAccountHost}/user/login`, {
        email,
        password
    }, {
        withCredentials: true,
        //@ts-ignore
        crossDomain: true
    }).then((value) => {
        console.log(value.data);
        if (!value.data.success) {
            cb(value.data.errorText);
        } else {
            localStorage.setItem("accessToken", value.data.accessToken);
            localStorage.setItem("refreshToken", value.data.refreshToken);
            cb(null);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const logout = (cb: (errorText: string | null) => void) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
}

export const startRegistration = (email: string, password: string, socialNetwork: string, cb: (errorText: string | null) => void) => {
    axios.post(`${userAccountHost}/user/start-registration`, {
        email,
        password,
        socialNetwork
    }).then((value) => {
        console.log(value.data);
        if (!value.data.success) {
            console.log(value.data.errorText);
            cb(value.data.errorText);
        } else {
            console.log(null);
            cb(null);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const getTmsellerInfo = (cb: (errorText: string | null, tmsellerInfo?: TMSellerInfoInterface) => void) => {
    axios.get(`${userAccountHost}/tmseller/info`).then(value => {
        if (value.data) {
            cb(null, value.data);
        } else {
            throw new Error();
        }
    }).catch((err) => {
        if (err.response) {
            cb(err.response.errorText);
        } else {
            cb("Неизвестная ошибка");
        }
    });
}

/*
export const endRegistration = (url: string, cb: (errorText: string | null) => void) => {
    axios.post(url).then((value) => {
        if (!value.data.success) {
            cb(value.data.errorText);
        } else {
            cb(null);
        }
    }).catch(err => {
        cb(err.message || "Неизвестная ошибка");
    });
}

export const createOrder = (product: string, content: string, cb: (errorText: string | null) => void) => {
    axios
        .post(`${host}/${product}/createOrder`, content)
        .then((value) => {
            if (value.data.isSuccess) {
                cb(null, value.data.orderId);
            } else {
                cb(`Непредвиденная ошибка при создании ордера`);
            }
        })
        .catch((err) => {
            console.log(err);
            cb(err);
        });
};

export const getMyIp = (cb) => {
    axios
        .get("https://api.ipify.org/?format=json")
        .then((value) => {
            cb(null, value.data.ip);
        })
        .catch((err) => {
            cb(err);
        });
};

export const getOrderStatus = (product, orderId, cb) => {
    axios
        .post(`${host}/${product}/orderStatus`, { orderId })
        .then((value) => {
            if (value.data.isSuccess) {
                console.log({
                    isExist: value.data.isExist,
                    isPaid: value.data.isPaid,
                    isExecuted: value.data.isExecuted,
                });
                cb(null, {
                    isExist: value.data.isExist,
                    isPaid: value.data.isPaid,
                    isExecuted: value.data.isExecuted,
                });
            } else {
                cb(`Непредвиденная ошибка при получении статуса ордера`);
            }
        })
        .catch((err) => {
            cb(err);
        });
};

export const getTMSellerPaymentFormUrl = (orderId) => {
    return `${host}/TMSeller/yoomoneyPaymentForm/${orderId}`;
};

export const trademanagerStartRegistration = (email, login, password, socialNetwork, cb) => {
    axios.post(`${host}/Trademanager/start-registration`, {
        email, login, password, socialNetwork
    }).then(value => {
        console.log(value.data)
        if (value.data.isSuccess) {
            cb();
        } else {
            cb(value.data.message);
        }
    }).catch((err) => {
        console.log(err);
        if (err.response) {
            cb(err.response.message);
        } else {
            cb("Неизвестная ошибка");
        }

    });
};

export const getTmsellerInfo = (cb) => {
    axios.get(`${userAccountHost}/tmseller/info`).then(value => {
        if (value.data) {
            cb(null, value.data);
        } else {
            throw new Error();
        }
    }).catch((err) => {
        if (err.response) {
            cb(err.response.errorText);
        } else {
            cb("Неизвестная ошибка");
        }
    });
}

export default {
    createOrder,
    getOrderStatus,
    getMyIp,
    getTMSellerPaymentFormUrl,
    trademanagerStartRegistration
};
*/